<template>
	<input
		type="text"
		:value="value"
		class="form-control"
		@input="$emit('input', $event.target.value)"
		:required="required"
		:disabled="disabled"
	/>
</template>

<script>
	export default {
		name: 'VText',
		data(){
			return {input: ""}
		},
		props: {
			value: {
				required: true
			},
			disabled: {
				type: Boolean,
				default: () => false
			},
			required: {
				type: Boolean,
				default: () => false
			},
		},
	}
</script>



