<template>
	<Layout>
		<div>
			<SaveSpinner :loadFlag="ui.loadingflag"/>
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="ui.loadingflag === false">
				<div class="content">
					<form class="my-form" @submit.prevent="listYearlyStats">
						<div class="form-group">
							<FEC n="Report">
								<VSelect v-model="type" :q="renderSelectOptions()" :value="type" :v="{}"/>
							</FEC>
						</div>
						<div class="form-group">
							<FEC :n="$t('year')">
								<VNumber v-model="year"></VNumber>
							</FEC>
						</div>
						<button type="submit" class="mx-auto btn btn-info d-block">{{$t('refresh')}}</button>
						<div class="form-group row no-gutters">
							<Feedback class="mx-auto" :successMessage="$t('data_saved')" />
						</div>
					</form>
					<table class="mx-auto text-right table-striped-even">
						<thead>
							<tr>
								<th>{{$t('month')}}</th>
								<th>{{sendYear}}</th>
								<th>{{sendYear - 1}}</th>
								<th>{{sendYear - 2}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="stat in yearlystats" :key="stat.monthnr">
								<!--<router-link to="/statistics/traffic/monthly"></router-link>-->
								<td>{{ $t('month_' + stat.monthnr) }}</td>
								<td class="fauxlink" v-on:click="listTransactions(printStartDate(sendYear, stat.monthnr), printEndDate(sendYear, stat.monthnr))">{{stat.year1.toLocaleString()}}</td>
								<td class="fauxlink" v-on:click="listTransactions(printStartDate(sendYear - 1,stat.monthnr), printEndDate(sendYear - 1, stat.monthnr))">{{stat.year2.toLocaleString()}}</td>
								<td class="fauxlink" v-on:click="listTransactions(printStartDate(sendYear - 2,stat.monthnr), printEndDate(sendYear - 2, stat.monthnr))">{{stat.year3.toLocaleString()}}</td>
							</tr>
						</tbody>
					</table>

<br>

					<SaveSpinner :loadFlag="loadingSubReportFlag" />
					<div v-if="loadingSubReportFlag === false" class="overflow-auto">
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from "../layouts/Layout.vue";
import SaveSpinner from "../views/Spinner.vue";
import Error from "../views/Error.vue";
import Feedback from "../views/Feedback.vue";
import cloneDeep from "lodash.clonedeep";
//import { required, minLength, alpha, numeric } from 'vuelidate/lib/validators'
import Vuex from "vuex";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;
//html elements
import VSelect from "../views/htmlelements/VSelect.vue";
import VNumber from "../views/htmlelements/VNumber.vue";
import FEC from "../views/htmlelements/FormElementContainer.vue";
//abstract page
import Page from "../pages/Page.vue";

//transactions must be declared in data even though we later set it in listTransactions or any validation won't bind to it! May not be necessar here though since we're not modifying it.
export default {
	data() {
		return {
			type: 'booking',
			year: new Date().getFullYear(),
			sendYear: new Date().getFullYear(),
			loadingSubReportFlag : false,
			transactions: cloneDeep(this.$store.state.transactions),
			yearlystats: cloneDeep(this.$store.state.yearlystats),
			ref_yearsback: [ {"text" : "1" , "value":"1" }, {"text" : "2" , "value":"2" } ]
		};
	},
	computed: {
		...ms(["ui"])
	},

/*
	beforeRouteUpdate(to,from,next) {
		listTransactions()
		next()
	},
*/

	components: { Error, Layout, SaveSpinner, Feedback, VSelect, FEC, VNumber },
	mixins: [Page],
	methods: {
		created: function() {},
		/**@param {number} yearNr
		 * @param {number} monthNr
		 */
		printStartDate: function(yearNr, monthNr){
			return `${yearNr}-${monthNr.toLocaleString(undefined, {minimumIntegerDigits: 2})}-01`
		},
		/**@param {number} yearNr
		 * @param {number} monthNr
		 */
		printEndDate: function(yearNr, monthNr){
			return `${yearNr}-${monthNr.toLocaleString(undefined,{minimumIntegerDigits: 2})}-${new Date(yearNr, monthNr, 0).getDate()}`
		},
		listYearlyStats: async function() {
			this.$store.commit("setui", { loadingflag: true });
			try {
				await this.$store.dispatch("listyearlystats", {
					type: this.type,
					year: this.year
				});
				this.sendYear = this.year;
				this.yearlystats = cloneDeep(this.$store.state.yearlystats)
			} catch(e) {
				this.$store.commit("setui", { errorflag: true });
			} finally{
				this.$store.commit("setui", { loadingflag: false })
			}
		},
		listTransactions: async function(startdate,enddate) {
			this.loadingSubReportFlag = true;
			try {
				if(this.type==='booking'){
					await this.$store.dispatch("listtransactions", {
						type: 'open',
						startdate: startdate,
						enddate: enddate
					});
				} else if(this.type==='request'){
					await this.$store.dispatch("listtransactions", {
						type: 'request',
						startdate: startdate,
						enddate: enddate
					});
				} else if(this.type==='hits'){
					await this.$store.dispatch("getpartnerhits", {
						startdate: startdate,
						enddate: enddate
					});
				} else if(this.type==='pageview'){
					await this.$store.dispatch("getpartnerpageviews", {
						startdate: startdate,
						enddate: enddate
					});
				}
			} catch {
				this.$store.commit("setui", {  errorflag: true });
			} finally{
				this.$store.commit("setui", { loadingflag: false })
			}
			this.loadingSubReportFlag = false
			if(this.type==='booking'){
				this.$router.push({name:'monthlytraffic' , params:{transactions: cloneDeep(this.$store.state.transactions),startdate:startdate, transactionType: "statistics"}}).catch(()=>{});
			} else if(this.type==='request'){
				this.$router.push({name:'monthlyrequests' , params:{transactions: cloneDeep(this.$store.state.transactions),startdate:startdate, transactionType: "statistics"}}).catch(()=>{});
			} else if(this.type==='hits'){
				this.$router.push({name:'monthlyhits' , params:{hits: cloneDeep(this.$store.state.hits),startdate:startdate}}).catch(()=>{});
			} else if(this.type==='pageview'){
				this.$router.push({name:'monthlypageviews' , params:{hits: cloneDeep(this.$store.state.pageviews),startdate:startdate}}).catch(()=>{});
			}
		},
		renderSelectOptions: function(){
			let tmp =  [
				{"text" : this.$t("de_request") , "value":"request" },
				{"text" : this.$t("de_booking") , "value":"booking" },
				{"text" : this.$t("de_hits") , "value":"hits" }
			]
			if(this.$store.getters.isPageViewsEnabled){
				tmp.push({"text" : this.$t("de_pageview") , "value":"pageview" })
			}
			return tmp
		}
	},

	//I am executed everytime this component is intialized.
	created() {
		this.listYearlyStats(); //loads the partner data
	}
};
</script>

<style>

.router-link-active {
  color: red;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s ease;
}


.fade-leave-active {
  transition: opacity .5s ease;
  opacity: 0;
}


</style>
