<template>
	<nav class="navbar navbar-default navbar-expand-lg">
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item active">
					<router-link to="/home" class="dropdown-item">{{$t('home_nav')}}</router-link>
				</li>
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{$t('partnerdaten_change_nav')}}</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
						<div v-if="hasAccess(2)">
							<router-link to="/partnerdata/edit" class="dropdown-item">{{$t('partnerdaten_change_nav')}}</router-link>
						</div>
						<div v-if="hasAccess(4)">
							<router-link to="/partnerdata/password" class="dropdown-item">{{$t('passwordchange_nav')}}</router-link>
						</div>
						<div class="dropdown-divider" v-if="hasAccess(256)"></div>
						<div v-if="hasAccess(256)">
							<router-link to="/partnerdata/users" class="dropdown-item">{{$t('useradmin_nav')}}</router-link>
						</div>
						<div class="dropdown-divider" v-if=" hasAccess(8)"></div>
						<div v-if="hasAccess(8)">
							<router-link to="/partnerdata/topsort" class="dropdown-item">{{$t('partnerdaten_topsort')}}</router-link>
						</div>
						<div class="dropdown-divider" v-if="hasAccess(256)"></div>
						<div v-if="hasAccess(256)">
							<router-link to="/partnerdata/organizers" class="dropdown-item">{{$t('organizeradmin_nav')}}</router-link>
						</div>
						<div class="dropdown-divider" v-if="hasAccess(32) ||hasAccess(256)"></div>
						<div v-if="hasAccess(256)">
							<router-link to="/icbe/data/edit" class="dropdown-item">{{$t('icbe_partnerdata_nav')}}</router-link>
						</div>
						<div v-if="hasAccess(32)">
							<router-link to="/wlv/edit" class="dropdown-item">{{$t('layout_nav')}}</router-link>
						</div>
					</div>
				</li>
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{$t('booking_nav')}}</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
						<div>
						<router-link to="/statistics/bookingreport" class="dropdown-item">{{$t('enquiry_nav')}}</router-link>
						</div>
						<div>
						<router-link to="/statistics/traffic" class="dropdown-item">{{$t('statistics')}}</router-link>
						</div>
						<div>
						<router-link to="/statistics/overview" class="dropdown-item">{{$t('overview_nav')}}</router-link>
						</div>
					</div>
				</li>
				<li class="nav-item dropdown" v-if="![109,111,112].includes($store.getters.getAppMandant)">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Marketing</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
						<div>
							<router-link to="/marketing/offertemplates" class="dropdown-item">Angebotsvorlagen</router-link>
						</div>
						<div>
							<router-link to="/marketing/banner" class="dropdown-item">Bannner</router-link>
						</div>
					</div>
				</li>
				<!-- Das ist die dümmste Anpassung, die es geben kann, warum sollte es für diese Mandanten diese Option nicht geben? -->
				<li class="nav-item" v-if="![109,111,112].includes($store.getters.getAppMandant)">
					<router-link to="/provisions" class="dropdown-item">Auszahlungshistorie</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/Logout" class="dropdown-item">Logout</router-link>
				</li>
			</ul>

			<div class="pr-2">
				<form :action="`${getWLVDomainLocal()}wlv/index.cfm?partnerID=${$store.state.partnerid}&fuseaction=login.doLogin&pservice=cbe`" method="post" target="_blank" v-if="$store.state.iscbe">
					<input type="hidden" name="wlvtoken" :value="$store.state.wlvtoken" />
					<button type="submit" class="btn btn-outline-info">
						CBE-Login <i style="color: var(--bs-primary)" class="fa fa-external-link"></i>
					</button>
				</form>
				<form :action="`${getWLVDomainLocal()}wlv/index.cfm?partnerID=${$store.state.partnerid}&fuseaction=login.doLogin&pservice=wlv`" method="post" target="_blank" v-else-if="!$store.state.iscbe">
					<input type="hidden" name="wlvtoken" :value="$store.state.wlvtoken" />
					<button type="submit" class="btn btn-outline-info">
						WLV-Login <i style="color: var(--bs-primary)" class="fa fa-external-link"></i>
					</button>
				</form>
			</div>
		</div>
	</nav>
</template>
<script>
import { getWLVDomain } from '../lib/mandant';

	export default {
		methods: {
			logout: function() {
				this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
			},
			getWLVDomainLocal: function() {
				return getWLVDomain(this.$store.state.auth.mandant);
			},
			hasAccess: function(nr){
				return this.$store.getters.getAccessToken & nr
			}
		}
	};
</script>
<style>

	@media (min-width: 992px) {
		.nav-bar .navbar-default{
			padding: 0;
		}

		.navbar-default .navbar-collapse {
			background-color: var(--bs-primary) !important;
			flex-basis: 100%;
			flex-grow: 1;
			align-items: center;
			margin: .75rem 0;
		}
		.navbar-default .navbar-nav > li > a {
			color: #ffffff;
		}

		.navbar-default .navbar-nav {
			margin: 8px -8px;
			margin-left: 1rem !important;
		}
	}

	@media screen and (max-width: 991px) {
		.navbar-default .navbar-collapse {
			flex-basis: 100%;
			flex-grow: 1;
			background-color: white !important;
			align-items: center;
			color: #ffffff !important;
			width: 100%;
		}
		.navbar-default .flags {
			display: none;
		}
		.navbar-default .navbar-nav > li > a {
			color: var(--bs-primary);
		}
	}

	.navbar-default .navbar-nav > li > a:hover,
	.navbar-default .navbar-nav > li > a:focus {
		background-color: var(--bs-primary);
	}

	.navbar-default .navbar-nav > li > a {
		border-bottom: 1px solid var(--bs-primary);
		filter: dropshadow(color=rgba(0, 0, 0, 0.2), offx=1, offy=1);
		-webkit-filter: dropshadow(color=rgba(0, 0, 0, 0.2), offx=1, offy=1);
		font-size: 1.1rem;
		font-family: Arial, sans-serif;
		font-weight: 400;
		font-style: normal;
		line-height: 1.3em;
		padding: 5px 10px !important;
	}

	.navbar-default .navbar-nav > li > .dropdown-menu {
		background-color: #ffffff;
		color: black;
	}
	.navbar-default .navbar-nav > li > a:hover {
		color: black !important;
	}
	.navbar-default .navbar-nav > li > a:focus {
		color: black !important;
	}
</style>