<template>
	<Layout>
		<div class="content">
			<SaveSpinner :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="row">
					<div class="col-3">
						<div class="card">
							<div class="p-1">
								<div v-for="bereich in bereiche" :key="bereich">
									<label>
										<input type="radio" name="templateBereich" :value="bereich" v-model="picked" v-on:change="bereichChanged(bereich)" />
										{{ bereich }}
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-9">
						<div v-if="offerTemplates.some(t => t.title.toLowerCase() === ('offertemplate-'+picked.toLowerCase()))">
							<div>
								<object :data="'data:application/pdf;base64,'+offerTemplates.find(t => t.title === ('offertemplate-'+picked))?.base64data ?? ''" type="application/pdf" width="700" height="550"></object>
							</div>
							<button type="button" class="btn" @click="downloadTemplate()">
								<i class="fa fa-download"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../layouts/Layout.vue";
	import Error from "../../views/Error.vue";
	import SaveSpinner from "../../views/Spinner.vue";
	import cloneDeep from "lodash.clonedeep";
	import Vuex from "vuex";
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;

	import Page from "../../pages/Page.vue";
import { downloadFile } from "../../lib/file";

	export default {
		data(){
			return {
				/** @type any[] */
				offerTemplates: cloneDeep(this.$store.state.offertemplates ?? []),
				pendingLoad: false,
				/** @type Map<number, Blob> */
				loadedOfferTemplates: new Map(),
				picked: "",
				bereiche: [
					"Allgemein",
					"Afrika",
					"Alaska",
					"Asien",
					"Hawaii",
					"Karibik",
					"Mittelmeer",
					"Nordland",
					"Nordamerika",
					"Orient u. Emirate",
					"Fluss"
				]
			}
		},
		components: {
			Layout,
			Error,
			SaveSpinner,
		},
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},
			...ms(["ui"])
		},
		mixins: [Page],
		methods: {
			/** @param {string} bereich */
			bereichChanged(bereich){
				this.loadedOfferTemplates.set(bereich, undefined);
				console.log(bereich, this.loadedOfferTemplates);
			},
			listOfferTemplates: async function (){
				this.$store.commit("setui", { loadingflag: true });
				try {
					await this.$store.dispatch("listOfferTemplates", {});

					this.offerTemplates = cloneDeep(this.$store.state.offertemplates ?? []);

					console.log(this.offerTemplates)
				} catch {
					this.$store.commit("setui", {  errorflag: true });
				} finally{
					this.$store.commit("setui", { loadingflag: false })
				}
			},
			/** @param {number} fileId */
			downloadTemplate() {
				this.pendingLoad = true;

				this.$store.dispatch("downloadOfferTemplate", {fileId: this.offerTemplates.find(t => t.title === ('offertemplate-'+this.picked)).fileid ?? 0})
				.then((blob) => {
					downloadFile(blob, "offertemplate-"+this.picked+".pdf");
					
					this.pendingLoad = false
				})
				.catch(() => {
					this.pendingLoad = false
				});
			}
		},
		created(){
			this.listOfferTemplates();
		}
		
	};
</script>