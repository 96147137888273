<template>
					<table class="mx-auto table-striped-even">
						<thead>
							<tr>
								<th>
									<button class="btn btn-outline-info" @click="exportTransactions" title="export data as Excel">
										<i class="fa fa-file-excel-o"></i>
									</button>
								</th>
								<th>{{$t('date')}}</th>
								<th>{{$t('state')}}</th>
								<th>{{$t('bookingnr')}}</th>
								<th class="white-space-nowrap">{{$t('travelid')}}</th>
								<th>{{$t('ship')}}</th>
								<th>{{$t('departure')}}</th>
								<th>{{$t('arrival')}}</th>
								<th>{{$t('route')}}</th>
								<th>{{$t('customers')}}</th>
								<th class="white-space-nowrap" v-if="user.partnerType == 1">{{$t('traveler')}}</th>
								<th class="white-space-nowrap">{{$t('travel price')}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="transaction in transactions" :key="transaction.bookingid">
								<td></td>
								<td class="white-space-nowrap">{{printDateTime(transaction.date, ' HH:MM')}}</td>
								<td>{{transaction.status}}</td>
								<td>{{transaction.bookingnr}}</td>
								<td>{{transaction.routeplanid}}</td>
								<td>{{transaction.shipname}}</td>
								<td>{{printDate(transaction.reiseterminvon)}}</td>
								<td>{{printDate(transaction.reiseterminbis)}}</td>
								<td>{{transaction.name}}</td>
								<td>{{transaction.customername}}</td>
								<td v-if="user.partnerType == 1">{{transaction.travelername}}</td>
								<td class="white-space-nowrap">{{transaction.bookingpricebrutto.toLocaleString(undefined,{minimumFractionDigits: 2, maximumFractionDigits: 2})}} €</td>
							</tr>
						</tbody>
					</table>
</template>

<script>
import {saveAs} from 'file-saver';
import writeXlsxFile from 'write-excel-file';

export default {
	data() {
		return {
			user: this.$store.state.auth
		};
	},
        props:{
            transactions:{
                type:Array,
								default: () => []
            },
						transactionType: {
							type: String,
							default: () => "transactions"
						}
        },

		methods: {
			printDate(dateString){
				if(dateString === null){
					return "";
				}
				let tmp = new Date(dateString);
				return tmp.toLocaleDateString();
			},
			printDateTime(dateString, mask){
				if(dateString === null){
					return "";
				}

				let tmp = new Date(dateString);

				mask = mask.replace(/HH/, tmp.getHours() < 10 ? '0'+tmp.getHours() : tmp.getHours());
				mask = mask.replace(/MM/, tmp.getMinutes() < 10 ? '0'+tmp.getMinutes() : tmp.getMinutes());

				return tmp.toLocaleDateString() + mask;
			},
			formatDate: function (date, mask = "yyyy-mm-dd"){
				const month = date.getMonth() + 1;
				const day = date.getDate();

				mask = mask.replace(/yyyy/, date.getFullYear().toString());
				mask = mask.replace(/mm/, month > 9 ? month.toString() : "0" + month);
				mask = mask.replace(/dd/, day > 9 ? date.getDate().toString() : "0" + day);

				return mask;
			},
			exportTransactions: async function() {
				const schema = [
					{column: this.$t('date'), type: String, value: item => new Date(item.date).toLocaleString(), width: 18, align: "right"},
					{column: this.$t('state'), type: String, value: item => item.status},
					{column: this.$t('no.'), type: String, value: item => item.bookingnr, width: 17},
					{column: this.$t('travelid'), type: Number, value: item => item.routeplanid, align: "left"},
					{column: this.$t('ship'), type: String, value: item => item.shipname, width: 20},
					{column: this.$t('departure'), type: String, value: item => new Date(item.reiseterminvon).toLocaleDateString(), width: 10, align: "right"},
					{column: this.$t('arrival'), type: String, value: item => new Date(item.reiseterminbis).toLocaleDateString(), width: 10, align: "right"},
					{column: this.$t('route'), type: String, value: item => item.name, width: 25},
					{column: this.$t('customers'), type: String, value: item => item.customername, width: 14},
					{column: this.$t('traveler'), type: String, value: item => item.travelername, width: 17},
					{column: this.$t('travel price'), type: String, value: item => item.bookingpricebrutto.toLocaleString(undefined,{style: 'currency', currency: 'EUR'}), width: 14, align: "right"}
				]
				let filenamePrefix = "ehoi"
				switch(this.$store.state.auth.mandant){
					case 104:
					filenamePrefix = "cruisewinkel"
					break;
					case 105:
					filenamePrefix = "wondercruises"
					break;
					case 106:
					filenamePrefix = "zeetours"
					break;
					case 107:
					filenamePrefix = "cruisetravel"
					break;
					case 109:
					filenamePrefix = "ummundo"
					break;
					case 111:
					filenamePrefix = "ummundo"
					break;
					case 112:
					filenamePrefix = "wondercruises"
					break;
				}

				let filename = `${filenamePrefix}-${this.transactionType}-${this.formatDate(new Date())}.xlsx`;
				let fileToExport = await writeXlsxFile(this.transactions, {schema});
				saveAs(fileToExport, filename);
			}
		}

}
</script>

<style>
	.white-space-nowrap{
		white-space: nowrap;
	}

	.btn-outline-info{
		background-color: white !important;
	}
</style>