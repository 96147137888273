<template>
	<div class="savespinner" v-if="loadFlag">
		<div class="spinner-border mx-auto d-block" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
</template>

<style>
.savespinner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
}
</style>

<script>

export default {

        props:{
            loadFlag:{
                type:Boolean,
                required:true
            }
        },

};
</script>