<template>
	<div>
		<div>
			<FEC :n="$t('email_contact')" :v="v.selectedConfigText.emailcontact">
				<VText v-model="value.emailcontact" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('email_transaction')" :v="v.selectedConfigText.emailfrom">
				<VText v-model="value.emailfrom" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('email_contact_text')">
				<VArea v-model="value.contactinformationtext" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('email_footer')">
				<VArea v-model="value.emailfootertext" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('email_advert_text')">
				<VArea v-model="value.werbetext" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('agb_file')">
				<div slot="label">
					<span>
						{{$t('agb_file')}}
						<i role="button" class="fa fa-info ml-2" @click="toggleInfo"></i>
					</span>
					<div class="position-relative">
						<div class="popover" :hidden="hideInfo">
							<div class="popover-body">
								{{$t("agb_file_info")}}
							</div>
						</div>
					</div>
				</div>
				<VFile class="btn btn-info" nn="PartnerLogoImage" v-on:change="changeLogoName" :a="`.pdf`" :n="$t('change_file')" />
				<span class="pl-1">{{value.agbfile !== null ? value.agbfile : ''}}</span>
			</FEC>
		</div>
		<div>
			<FEC :n="$t('agb')">
				<VText v-model="value.agblink" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('impressum')">
				<VText v-model="value.impressumlink" @input="emitValue" />
			</FEC>
		</div>
		<div>
			<FEC :n="$t('privacy')">
				<VText v-model="value.privacylink" @input="emitValue" />
			</FEC>
		</div>
	</div>
</template>

<script>
//html elements
import VText from "../htmlelements/VText.vue";
import VArea from "../htmlelements/VArea.vue";
import VFile from "../htmlelements/VFile.vue";
import FEC from "../htmlelements/FormElementContainer.vue";

export default {
	props:{
		value:{
			type: Object,
			required: true
		},
		v: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			hideInfo: true
		};
	},
	components: { FEC, VText, VArea, VFile },
	methods: {
		emitValue: function(){
				this.$emit("input", this.value);
		},
		toggleInfo: function(){
			this.hideInfo = !this.hideInfo
		},
		changeLogoName: function(input){
			const reader = new FileReader();

			reader.onloadend = () => {
				this.value.agbencoded = reader.result;
				this.emitValue();
			}

			this.value.agbfile = input.files[0]?.name;

			reader.readAsDataURL(input.files[0]); // convert to base64 string
		}
	}
};
</script>