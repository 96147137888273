import de from './translations/de';
import en from './translations/en';
import nl from './translations/nl';
import be from './translations/be';
import fr from './translations/fr';
import es from './translations/es';
import pt from './translations/pt';

export default {
  de,
  en,
	be,
	es,
	fr,
	nl,
	pt
};