<template>
	<Layout>
		<div class="content">
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div class="d-flex">
				<div class="mx-auto pb-4">
					<p class="font-weight-bold text-secondary">{{$t('home_welcome',[mandantTranslations[$store.getters.getAppMandant] ?? "E-hoi"])}}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-auto mx-auto" v-if="showNewsletter($store.getters.getAppMandant)">
					<a :href="externalLinks.find(t => t.language == $i18n.locale)?.newsletter ?? externalLinks.at(0)?.newsletter ?? ''" class="text-secondary" target="blank" v-html="$t('overview_link_newsletter_anmeldung')"></a>
				</div>
				<div class="col-auto mx-auto">
					<a :href="getWLVpath('wlv/')" class="text-secondary" target="blank" v-html="$t('overview_link_IBE')"></a>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	//abstract page
	import Page from "../pages/Page.vue";
	//vuex
	import Vuex from "vuex";
	import {mandantTranslations, getWLVDomain, showNewsletter} from "../lib/mandant.js";

	const ms = Vuex.mapState;

	export default {
		components: { Layout, SaveSpinner },
		mixins: [Page],
		data: () => {
			return {
				externalLinks: [],
				mandantTranslations: mandantTranslations,
				showNewsletter: showNewsletter
			}
		},
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},
			...ms(["ui"])
		},
		methods: {
			getWLVpath: function(url){
				return getWLVDomain(this.$store.state.auth.mandant) + url + "?partnerid=" + this.$store.state.partnerid
			},
			loadLinks: async function(){
				this.$store.commit("setui", { loadingflag: true });

				await this.$store.dispatch("loadExternalLinks", this.$store.getters.getAppMandant)
				.then((resp) => {
					this.externalLinks = resp.data.map(l => {
						l.newsletter = "https://"+l.newsletter;
						return l
					})
				})
				.catch(() => {
					this.$store.commit("setui", { errorflag: true });
				})
				.finally(() => {
					this.$store.commit("setui", { loadingflag: false });
				});
			}
		},
		mounted: function(){
			this.loadLinks()
		}
	};
</script>
<style scoped>
	a{
		display: block;
		text-align: center;
	}
</style>