<template>
	<Layout>
		<div>
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="content">
					<form class="my-form" @submit.prevent="saveWLV">
						<div class="d-flex mb-2">
							<span class="span-style">{{$t('icbe_partnerdata_nav')}}</span>
						</div>
						<div>
							<FEC :v="$v.wlv.firm" :n="$t('firma')">
								<VText v-model="wlv.firm" />
							</FEC>
						</div>
						<div>
							<FEC :n="$t('street') + '/' + $t('houseNr')">
								<div class="input-group">
									<VText class="w-75" v-model="wlv.street" />
									<VText class="w-25" v-model="wlv.housenr" maxlength="10" />
								</div>
							</FEC>
						</div>
						<div>
							<FEC :n="$t('zipcode') + '/' +$t('city')" :v="$v.wlv.zipcode">
								<div class="input-group">
									<VText class="w-25" v-model="wlv.zipcode" />
									<VText class="w-75" v-model="wlv.city" />
								</div>
							</FEC>
						</div>
						<div>
							<FEC :n="$t('country')">
								<VSelect v-model="wlv.country" :q="rerenderSelectOptions(ref_country)" :v="{}" />
							</FEC>
						</div>
						<div>
							<div class="row no-gutters">
								<div class="col-6 col-form-label">
									<div>
										<span>{{$t('phonenumber')}}</span>
										<i role="button" class="fa fa-info ml-2" @click="toggleInfo"></i>
									</div>
									<div class="position-relative">
										<div class="popover" :hidden="hideInfo">
											<div class="popover-body">
												{{$t("phonenumber_info")}}
											</div>
										</div>
									</div>
								</div>
								<div class="col-6 col-form-label">
									<div class="input-group">
										<VText class="px-1 w-50" v-model="wlv.phonecountry" :readonly="true" />
										<VText class="px-1 w-25" v-model="wlv.phonecity" :readonly="true" />
										<VText class="px-1 w-25" v-model="wlv.phonenumber" :readonly="true" />
									</div>
								</div>
							</div>
						</div>
						<div class="hr" />
						<div class="pt-2">
							<div>
								<div class="input-group">
									<label class="radio border-ehoi" v-bind:class="{checked: lang.id==selectedLang}" v-for="lang in restrictLanguages()" v-bind:key="lang.id">
										<div class="flag-language">
											<img :src="require(`@/assets/images/flag-${lang.code}.png`)" alt="" />
										</div>
										<input type="radio" name="optionNr" :value="lang.id" v-model="selectedLang" hidden @click="getConfigText(lang.id)" />
									</label>
								</div>
							</div>
							<div v-if="selectedConfigText.partnerid != null">
								<ConfigTextEdit v-model="selectedConfigText" :v="$v"></ConfigTextEdit>
							</div>
							<div v-if="selectedConfigText.partnerid == null">
								<p class="alert alert-warning">{{$t('missing_configText')}}</p>
								<button type="button" class="btn btn-info" @click="addConfigText(selectedLang)">
									<i class="fa fa-plus"></i> {{$t('add')}}
								</button>
							</div>
						</div>
						<div class="hr" />
						<div>
							<FEC :n="$t('email_show_advert')">
								<VCheckbox v-model="wlv.werbetextanzeigen" />
							</FEC>
							<small>{{$t('email_show_advert_info')}}</small>
						</div>
						<div class="hr" />
						<div class="d-flex mb-2">
							<span class="span-style">{{$t('internal_Bank_Transfer')}}</span>
						</div>
						<div>
							<FEC :v="$v.wlv.bank" :n="$t('bankname')">
								<VText v-model="wlv.bank" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.wlv.iban" n="IBAN">
								<VText v-model="wlv.iban" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.wlv.swiftcode" n="Swiftcode / BIC">
								<VText v-model="wlv.swiftcode" />
							</FEC>
						</div>
						<div class="form-group row">
							<button type="submit" class="mx-auto btn btn-info">
								<i class="fa fa-save"></i> {{$t('save')}}
							</button>
						</div>
					</form>
				</div>
			</div>
			<Feedback :successMessage="$t('data_saved')" />
		</div>
	</Layout>
</template>

<script>
import ConfigText from "../../model/ConfigText";
import Layout from "../../layouts/Layout.vue";
import SaveSpinner from "../../views/Spinner.vue";
import Feedback from "../../views/Feedback.vue";
import cloneDeep from "lodash.clonedeep";
import Vuex from "vuex";
//validators
//html elements
import VText from "../../views/htmlelements/VText.vue";
import VCheckbox from "../../views/htmlelements/VCheckbox.vue";
import VSelect from '../../views/htmlelements/VSelect.vue'
import FEC from "../../views/htmlelements/FormElementContainer.vue";
import ConfigTextEdit from "../../views/icbe/ConfigTextEdit.vue";
//abstract page
import Page from "../../pages/Page.vue";
	import {
		email,
		helpers
	} from "vuelidate/lib/validators";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;
	const isPattern = param =>
		helpers.withParams({ type: "pattern", bsp: param.bsp }, value =>
			!helpers.req(value) || value.match(param.pattern) !== null ? true : false
		);

export default {
	data() {
		return {
			hideInfo: true,
			wlv: cloneDeep(this.$store.state.wlv),
			ref_country: this.$store.state.stammdaten.country,
			languages: this.$store.state.languages,
			telnumbers: [],
			recomTelnumbers: [],
			selectedLang: 1,
			selectedConfigText: {
				partnerid: null,
				emailfrom: "",
				emailcontact: "",
				emailfootertext: "",
				werbetext: ""
			}
		};
	},
	components: { Layout, SaveSpinner, Feedback, FEC, VCheckbox, VSelect, VText, ConfigTextEdit },
	mixins: [Page],
	methods: {
		toggleInfo: function(){
			this.hideInfo = !this.hideInfo
		},
		restrictLanguages(){
			if([104,105,106,107].includes(this.$store.state.auth.mandant)){
				return this.languages.filter(l => l.id == 6)
			}

			return this.languages.filter(l => l.id == 1);
		},
		saveWLV: async function () {
			this.$v.$touch();
			this.$store.commit("resetui");
			if (this.$v.$invalid) {
				this.submitStatus = "ERROR";
				//console.log("invalid data!");
			} else {
				let wlvupdate = cloneDeep(this.wlv);
				this.$store.commit("setui", { loadingflag: true });
				await this.$store.dispatch("savewlv", { wlv: wlvupdate });
				this.wlv = cloneDeep(this.$store.state.wlv);
				this.getConfigText(this.selectedLang);
				this.$store.commit("setui", { loadingflag: false });
			}
		},
		getWLV: async function () {
			this.$store.commit("setui", { loadingflag: true });
			try {
				await this.$store.dispatch("getwlv", {});
				this.wlv = cloneDeep(this.$store.state.wlv);
				this.selectedLang = this.wlv.defaultlanguageid;
				this.getConfigText(this.selectedLang);
			} catch {
				this.$store.commit("setui", { errorflag: true });
			} finally {
				this.$store.commit("setui", { loadingflag: false });
			}
		},
		getConfigText: function(languageId){
			var foundconfig = this.wlv.texts.find((configtext) => {
				return configtext.languageid == languageId;
			});

			if(foundconfig){
				this.selectedConfigText = foundconfig
			}else{
				this.selectedConfigText = {
					partnerid: null,
					emailfrom: "",
					emailcontact: "",
					emailfootertext: "",
					werbetext: ""
				};
			}
		},
		addConfigText: function(languageId){
			let configtext = new ConfigText(this.wlv.partnerid, languageId);
			this.wlv.texts.push(configtext);
			this.selectedConfigText = configtext
		},
		rerenderSelectOptions: function(data){
			return data.map(t => {
				return {value: t.value, text: t.texts.find(txt => txt.language == this.$i18n.locale)?.label || t.texts.find(txt => txt.language == "de")?.label || ''};
			}).sort((a,b) => {
				if(a.text.toLowerCase() > b.text.toLowerCase()){return 1}
				else if(a.text.toLowerCase() < b.text.toLowerCase()){return -1}
				return 0;
			});
		},
		getTelnumbers: async function(){
			this.$store.commit("setui", { loadingflag: true });
			try {
				await this.$store.dispatch("listTelnumbers", {});
				let phonecodes = [];

				switch(this.$store.state.auth.mandant){
					case 104:
						phonecodes = ["BE","DE","NL"];
						break;
					case 105:
						phonecodes = ["BE","DE","NL"];
						break;
					case 106:
						phonecodes = ["BE","DE","NL"];
						break;
					case 107:
						phonecodes = ["BE","DE","NL"];
						break;
					case 109:
						phonecodes = ["ES"];
						break;
					case 111:
						phonecodes = ["PT"];
						break;
					case 112:
						phonecodes = ["FR"];
						break;
					default:
						phonecodes = ["AT","DE","CH"];
				}

				this.telnumbers = cloneDeep(this.$store.state.telnumbers)
				this.recomTelnumbers = this.telnumbers.filter(t => phonecodes.includes(t.countrycode));
			} catch(err) {
				this.$store.commit("setui", {  errorflag: true })
			} finally{
				this.$store.commit("setui", { loadingflag: false })
			}
		}
	},
	computed: {
		...ms(["ui"]),
	},
	//I am executed everytime this component is intialized.
	async created() {
			if((Object.keys(this.$store.state.stammdaten).length===0 || typeof this.$store.state.stammdaten !== 'object') && (this.$store.state.auth.status===1)){
				await this.$store.dispatch('getstammdaten')
				.then(() => {
					this.ref_country = this.$store.state.stammdaten.country;
				})
			}
		await this.getWLV(); //loads the wlv data
		await this.getTelnumbers();
	},
		validations: {
			wlv: {
				zipcode: {
					pattern: isPattern({pattern: /^\d{4,}[a-zA-Z]*$/, bsp: "1234"})
				},
				bank: {
					pattern: isPattern({ pattern: /^[a-zA-ZÀ-ž].*[a-zA-ZÀ-ž]$/, bsp: "A-Z" })
				},
				iban: {
					pattern: isPattern({ pattern: /^[A-Z]{2}[0-9]{18,}$/, bsp: "DE000011110000111100" })
				},
				swiftcode: {
					pattern: isPattern({ pattern: /^[A-Z]{6}[0-9A-Z]{5}$/, bsp: "AAAABBCC123" })
				}
			},
			selectedConfigText:{
				emailfrom: {
					email
				},
				emailcontact: {
					email
				}
			}
		}

};
</script>

<style scoped>
	.w-40{
		width: 40% !important;
	}
	.w-35{
		width: 35% !important;
	}
</style>