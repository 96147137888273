import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import { store } from './store'
import routes from './router'
import VueI18n from 'vue-i18n'
import messages from './lang'
import runner from './runners/axiosrunner'
import ErrorHandler from './errorhandler'
import VCalendar from 'v-calendar';



//for bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
//custom stuff
import './assets/css/style.css'

/** @param {number} mandant */
function selectLocale(mandant){
	if([104,106,107].includes(mandant)){
		return "nl"
	}else if([105].includes(mandant)){
		return "be"
	}else if([109].includes(mandant)){
		return "es"
	}else if([111].includes(mandant)){
		return "pt"
	}else if([112].includes(mandant)){
		return "fr"
	}

	return "de";
}

//register the router with vue  ,
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VCalendar)

//languages
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: selectLocale(store.getters.getAppMandant),
  fallbackLocale: 'de',
  messages
});


Vue.config.productionTip = false

/*** SETUP FOR ERROR HANDLING ***/


//If an error gets to here, our error handling has failed, since fnErrorHandler should be handling most intances of errors.
Vue.config.errorHandler = function(err,vm,info)
                          {
                            store.state.arrerror.push(err)
                            if(process.env.NODE_ENV==='developmentx'){
                              ErrorHandler.errorHandlerWithStack(err.errText,store,err.stack,err.body,err.action,navigator.userAgent,vm._routerRoot._route.fullPath,info)
                              } else {
                              let f = new FormData();
                              f.set('action' , 'errorflash')
                              f.set('partnerid' , store.state.partnerid) //possibly a 0, the default value
                              f.set('msg',  'An global error handler level error occurred: ' + store.state.arrerror + " UserAgent: " + navigator.userAgent)
                              runner.doPost(f)
                                .then(resp => {
                                  //eslint-disable-next-line no-console
                                  console.log(resp)
                                })
                              .catch(err => {
                                 //eslint-disable-next-line no-console
                                  console.log('Global error handler error: ' + err)
                              })
                            }

                          }


Vue.config.warnHandler = function(msg, vm, info) { //warnings generated by vue, which only does this in development mode
    //eslint-disable-next-line no-console
    console.log('VUE Warning: ' + msg + ' Info:' + info)
    //console.dir(vm)
}

window.onerror = function(message, source, line, column, error) {
    //eslint-disable-next-line no-console
    console.log('Global Handler: ' + message + source + line + column + error)
}



const router = new VueRouter({routes , mode:'history' , base: process.env.BASE_URL });

router.beforeEach((to, from, next) => {

  //make sure the stammdaten has been successfully loaded. We always check in case of a previous failure due to server error, etc, which would otherewise leave the app unusable
  // if((Object.keys(store.state.stammdaten).length===0 || typeof store.state.stammdaten !== 'object') && (store.state.auth.status===1)){
  //   store.commit("setui", { loadingflag: true })
  //   store.dispatch('getstammdaten') //let the callback take care of resetting the ui flag
  // }

  //check if authenticated
  if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
          //check access level. Is the user allowed to view the page?
          if (to.matched.some(record => record.meta.accessLevel)) {
            if(store.getters.getAccessToken & to.meta.accessLevel)
            {
              next()
              return
            } else { // no access? redirect to home
              next('/home')
            }
          }
        }
        next('/login')
      } else {
        next()
      }



})



const app = new Vue({
      el: '#app',
      store,
      router,
      i18n,
      render: h => h(App),
      methods: {
        increment () {
          store.commit('increment')
        },
        decrement () {
          store.commit('decrement')
        }
      }
})

store.$app = app
app.$mount('#app')