<template>
	<div>
				<a
					v-for="entry in languages"
					:key="entry.title"
					@click="changeLocale(entry.language)"
					class="flag-language mr-1"
				>
					<img :src="entry.icon" alt="" />
				</a>
	</div>
</template>

<style>
div .locale button {
	padding: 15px;
	border: 1px solid green;
	float: right;
	font-size: 18px;
	margin: 15px;
}
</style>

<script>
//import i18n from '@/plugins/i18n';

export default {
	data() {
		return {
			languages: [
				{
					flag: "us",
					language: "en",
					title: "English",
					icon: require("@/assets/images/flag-en.png"),
				},
				{
					flag: "de",
					language: "de",
					title: "Deutsch",
					icon: require("@/assets/images/flag-de.png"),
				},
			],
		};
	},
	created(){
		if([104,106,107].includes(this.$store.getters.getAppMandant)){
			this.languages = [
				{
					flag: "nl",
					language: "nl",
					title: "Niederländisch",
					icon: require("@/assets/images/flag-nl.png"),
				},
				{
					flag: "us",
					language: "en",
					title: "English",
					icon: require("@/assets/images/flag-en.png"),
				},
			]
		}else if([105].includes(this.$store.getters.getAppMandant)){
			this.languages = [
				{
					flag: "be",
					language: "be",
					title: "Flämisch",
					icon: require("@/assets/images/flag-be.png"),
				},
				{
					flag: "fr",
					language: "fr",
					title: "Französisch",
					icon: require("@/assets/images/flag-fr.png"),
				},
			]
		}else if([109].includes(this.$store.getters.getAppMandant)){
			this.languages = [
				{
					flag: "es",
					language: "es",
					title: "Spanisch",
					icon: require("@/assets/images/flag-es.png"),
				},
			]
		}else if([111].includes(this.$store.getters.getAppMandant)){
			this.languages = [
				{
					flag: "pt",
					language: "pt",
					title: "Portugiesisch",
					icon: require("@/assets/images/flag-pt.png"),
				},
			]
		}else if([112].includes(this.$store.getters.getAppMandant)){
			this.languages = [
				{
					flag: "fr",
					language: "fr",
					title: "Französisch",
					icon: require("@/assets/images/flag-fr.png"),
				},
			]
		}
	},
	methods: {
		changeLocale(locale) {
			this.$i18n.locale = locale;
		},
	},
};
</script>