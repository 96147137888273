<template>
	<div class="col-6">
		<div class="input-group">
			<select class="form-control" :required="required" v-model="phoneInternational" @change="emitValue">
				<option value=""></option>
				<optgroup label="meist benutzt">
					<option v-for="tel in recomendedTels" v-bind:key="tel.countrycode" v-bind:value="tel.phonecode">
						({{tel.countrycode}}) {{tel.phonecode}}
					</option>
				</optgroup>
				<optgroup label="country">
					<option v-for="tel in q" v-bind:key="tel.countrycode" v-bind:value="tel.phonecode" >
						({{tel.countrycode}}) {{tel.phonecode}}
					</option>
				</optgroup>
			</select>
			<input type="text" class="form-control" :required="required" v-model="phoneCountry" @input="emitValue">
			<input type="text" class="form-control" :required="required" v-model="phoneLocal" @input="emitValue">
		</div>
		<div class="error" v-if="!v.required || !v.pattern">
			{{$t('validation_required', [$t('phonenumber')+" *"])}}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Telnumber',
		data(){
			return {
				phoneInternational: "",
				phoneCountry: "",
				phoneLocal: ""
			};
		},
		props: {
			required: {
				type: Boolean,
				default: () => false
			},
			v: {
				type: Object,
				required: true
			},
			q: {
				type: Array,
				required: true
			},
			d: {
				type: Boolean,
				default: false
			},
			value: {
				required: true
			},
			recomendedTels:{
				type: Array,
				default: () => []
			}
		},
		created() {
			let result = this.value?.match(/(\+\d+)? (\d+)? (\d+)?/);
			if(result != null){
				this.phoneInternational = result[1]
				this.phoneCountry = result[2]
				this.phoneLocal = result[3]
			}
		},
		methods: {
			emitValue(){
				this.$emit("input", this.phoneInternational + " " + this.phoneCountry + " " + this.phoneLocal);
				// this.value = this.phoneCountry + " " + this.phone;
			}
		}
	}
</script>
