<template>
	<Layout>
		<div>
			<SaveSpinner :loadFlag="ui.loadingflag"/>
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="ui.loadingflag === false">
				<div class="content">
					<div class="row no-gutters">
						<Feedback class="mx-auto" :successMessage="$t('data_saved')" />
					</div>
					<div>
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
								<a class="nav-link active" id="destination-tab" data-toggle="tab" href="#destination" role="tab" aria-controls="destination" aria-selected="true">{{$t('destinations')}}</a>
							</li>
							<li class="nav-item" role="presentation">
								<a class="nav-link" id="organizer-tab" data-toggle="tab" href="#organizer" role="tab" aria-controls="organizer" aria-selected="false">{{$t('organizer')}}</a>
							</li>
							<li class="nav-item" role="presentation">
								<a class="nav-link" id="ships-tab" data-toggle="tab" href="#ships" role="tab" aria-controls="ships" aria-selected="false">{{$t('ships')}}</a>
							</li>
						</ul>
						<div class="tab-content pt-2" id="tabContent">
							<div class="tab-pane fade show active" id="destination" role="tabpanel" aria-labelledby="destination-tab">
								<TopSortOptions :q="'destination'" :topSortHochsee="filterTopSort(3)" :topSortFluss="filterTopSort(6)" />
							</div>
							<div class="tab-pane fade" id="organizer" role="tabpanel" aria-labelledby="organizer-tab">
								<TopSortOptions :q="'organizer'" :topSortHochsee="filterTopSort(2)" :topSortFluss="filterTopSort(5)" />
							</div>
							<div class="tab-pane fade" id="ships" role="tabpanel" aria-labelledby="ships-tab">
								<TopSortOptions :q="'ship'" :topSortHochsee="filterTopSort(4)" :topSortFluss="filterTopSort(7)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from "../layouts/Layout.vue";
import SaveSpinner from "../views/Spinner.vue";
import Error from "../views/Error.vue";
import TopSortOptions from "../views/topSort/TopSortOptions.vue";
import Feedback from "../views/Feedback.vue";
import cloneDeep from "lodash.clonedeep";
//import { required, minLength, alpha, numeric } from 'vuelidate/lib/validators'
import Vuex from "vuex";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;
//html elements
// import VSelect from "../views/htmlelements/VSelect.vue";
// import FEC from "../views/htmlelements/FormElementContainer.vue";
//abstract page
import Page from "../pages/Page.vue";

//transactions must be declared in data even though we later set it in listTransactions or any validation won't bind to it! May not be necessar here though since we're not modifying it.
export default {
	data() {
		return {
			topSortLists: cloneDeep(this.$store.state.partnertopsorts)
		};
	},
	computed: {
		...ms(["ui"])
	},
	components: { Error, Layout, SaveSpinner, Feedback, TopSortOptions },
	mixins: [Page],
	methods: {
		getTopSortLists: async function() {
			this.$store.commit("setui", { loadingflag: true });
			try {
				await this.$store.dispatch("getpartnertopsort", {});
				this.topSortLists = cloneDeep(this.$store.state.partnertopsorts);
			} catch {
				this.$store.commit("setui", { errorflag: true });
			} finally{
				this.$store.commit("setui", { loadingflag: false });
			}
		},
		filterTopSort(listTypeID){
			return this.topSortLists.find((item) => {
				return item.listtype == listTypeID;
			});
		}
	},
	created() {
		this.getTopSortLists();
	}
}
</script>