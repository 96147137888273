// @ts-check

/** @type Record<number,string> */
export const mandantLogo = {
	101: "website-logo_101.png",
	102: "website-logo_101.png",
	104: "website-logo_104.png",
	105: "website-logo_105.png",
	106: "website-logo_106.jpg",
	107: "website-logo_107.png",
	108: "website-logo_101.png",
	109: "website-logo_109.png",
	111: "website-logo_111.png",
	112: "website-logo_112.png"
}

/** @type Partial<Record<number,string>> */
export const mandantHref = {
	101: process.env.VUE_APP_FE_101 ?? "",
	102: process.env.VUE_APP_FE_102 ?? "",
	104: process.env.VUE_APP_FE_104 ?? "",
	105: process.env.VUE_APP_FE_105 ?? "",
	106: process.env.VUE_APP_FE_106 ?? "",
	107: process.env.VUE_APP_FE_107 ?? "",
	108: process.env.VUE_APP_FE_108 ?? "",
	109: process.env.VUE_APP_FE_109 ?? "",
	111: process.env.VUE_APP_FE_111 ?? "",
	112: process.env.VUE_APP_FE_112 ?? ""
}

/** @type Record<number,string> */
export const mandantAppTitle = {
	104: "cruisewinkel partnerware",
	105: "wondercruises partnerware",
	106: "zeetours partnerware",
	107: "cruisetravel partnerware",
	109: "ummundo partnerware",
	111: "ummundo partnerware",
	112: "wondercruises partnerware"
}

/** @type Record<number,string> */
export const mandantAppClass = {
	104: "mandant-104",
	105: "mandant-105",
	106: "mandant-106",
	107: "mandant-107",
	109: "mandant-109",
	111: "mandant-111",
	112: "mandant-112"
}

/** @type Record<number,string> */
export const mandantTranslations = {
	104: "Cruisewinkel",
	105: "Wondercruises",
	106: "Zeetours",
	107: "Cruisetravel",
	109: "Um mundo",
	111: "Um mundo",
	112: "Wondercruises"
}

/** @type Partial<Record<number,string>> */
const mandantWlvDomain = {
	101: process.env.VUE_APP_WLVPATH_101,
	102: process.env.VUE_APP_WLVPATH_102,
	104: process.env.VUE_APP_WLVPATH_104,
	105: process.env.VUE_APP_WLVPATH_105,
	106: process.env.VUE_APP_WLVPATH_106,
	107: process.env.VUE_APP_WLVPATH_107,
	109: process.env.VUE_APP_WLVPATH_109,
	111: process.env.VUE_APP_WLVPATH_111,
	112: process.env.VUE_APP_WLVPATH_112
}

/** 
 * @param {number} mandant 
 * @returns {string}
*/
export function getWLVDomain(mandant){
	return mandantWlvDomain[mandant] ?? "";
}

/** 
 * @param {number} mandant 
 * @returns {boolean}
*/
export function showNewsletter(mandant){
	return [106,109,111].includes(mandant);
}