<!-- r ist reference , um konsistenz su sein bitte unsere r muss genau das key von v-model sein , Bsp : v-model = "object.example" ==> r soll "example" geschikt werden. -->
<template>
    <div :id="r" class="input-group color-picker mb-3"  :ref="r">
        <input type="text" class="form-control" v-model="thecolorpicker" @focus="togglePicker" />
        <div class="input-group-append color-picker-container">
        <input type="button" class="btn btn-outline-secondary" :style="'background-color: ' + thecolorpicker" @click="togglePicker" />
        <color-picker class="color-picker-box" style="width: 218px;"  @changeColor="updateFromPicker" v-if="displayed" 
            :color=thecolorpicker
            :sucker-hide="false"
            :sucker-canvas="suckerCanvas"
            :sucker-area="suckerArea"
            @openSucker="openSucker"
        />
       </div>
    </div>
</template>
<script>
    import colorPicker from '@caohenghu/vue-colorpicker'
    export default {
        name:'VColorpicker'
        ,components: {colorPicker}
        ,
          data() {
            return {
                suckerCanvas: null,
                suckerArea: [],
                isSucking: false,
                displayed : false,
                arr: [],
          };
        },
        props:{
            r:{
                type:String,
                required:false,
                default: ""
            },
            value:{
                required:true
            }
        }
        ,
        computed:{
            thecolorpicker:{
                get(){
                    if(typeof this.value === 'undefined'){
                      return ''
                    } else {
                      return this.value
                    }
                  },
                  set(v){this.$emit('input',v);
                     }
                 }
           },
           methods: {
               openSucker(isOpen) {
              if (isOpen) {
                alert('isOpen');//TODO
              } else {
                    alert('not isOpen');
              }
          },
            showPicker() {
                document.addEventListener('click', this.documentClick);
                this.displayed = true;
            },
            hidePicker() { 
                
                document.removeEventListener('click', this.documentClick);
                this.displayed  = false;
            },
            togglePicker() {
                this.displayed  ? this.hidePicker() : this.showPicker();
            },
            RGBToHex(r,g,b) {
                r = r.toString(16);
                g = g.toString(16);
                b = b.toString(16);

                if (r.length == 1)
                    r = "0" + r;
                if (g.length == 1)
                    g = "0" + g;
                if (b.length == 1)
                    b = "0" + b;

                return "#" + r + g + b;
            },
            updateFromPicker(color) {
                const { r, g, b } = color.rgba;
                var hexColor = this.RGBToHex(r,g,b);
                this.thecolorpicker = hexColor;
            },
            documentClick(e){
                var index = e.target.id ; 
                var el = this.$refs[this.r],
                target = e.target;
                if(el !== target &&  !el.contains(target)) {
                this.hidePicker(index);
            }
         }
        }
    }

</script>
<style>
.color-picker-box {
    position: absolute;
    top: 35px;
    z-index: 9;
    margin-top: 0.2px;
}
.color-picker-container{
   position: relative;
}

</style>