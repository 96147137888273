<template>
	<div class="row no-gutters">
		<p v-if="n.length" class="col-6 col-form-label">
			<slot name="label">
				{{ n }}
			</slot>
		</p>
		<div class="col-6">
			<div>
				<slot />
			</div>
			<div class="error" v-if="'required' in v && !v.required">{{$t('validation_required', [n])}}</div>
			<div class="error" v-if="'alpha' in v && !v.alpha">{{$t('validation_alpha', [n])}}</div>
			<div class="error" v-if="'numeric' in v && !v.numeric">{{$t('validation_numeric', [n])}}</div>
			<div class="error" v-if="'email' in v && !v.email">{{$t('validation_email', [n])}}</div>
			<div class="error" v-if="'minLength' in v && !v.minLength" >{{$t('validation_minLength', [n,v.$params.minLength.min])}}</div>
			<div class="error" v-if="'mustBeUnique' in v && !v.mustBeUnique">{{$t('validation_mustBeUnique', [n])}}</div>
			<div class="error" v-if="'pattern' in v && !v.pattern && customText.length > 0">{{customText}}</div>
			<div class="error" v-if="'pattern' in v && !v.pattern && customText.length == 0">{{$t('validation_pattern', [n,v.$params.pattern.bsp])}}</div>
			<div class="error" v-if="'password' in v && !v.password">{{$t('validation_password', [n])}}</div>
			<div class="error" v-if="'sameAs' in v && !v.sameAs">{{$t('validation_sameAs', [n,r])}}</div>
			<div class="error" v-if="'sameAsPassword' in v && !v.sameAsPassword">{{$t('validation_sameAs', [n,r])}}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		v: {
			type: Object,
			required: false,
			default: function() {
				return { required: true, alpha: true, numeric: true, minLength: true };
			}
		},
		n: {
			type: String,
			required: false,
			default: ""
		},
		r: {
			type: String,
			required: false,
			default: ""
		},
		customText: {
			type: String,
			required: false,
			default: ""
		}
	}
};
</script>