<template>
	<label>
		{{n}}
		<input type="file" :name="nn" class="form-control d-none" :accept="a" v-on:change="$emit('change',$event.target)" />
	</label>
</template>

<script>
	export default {
		name:'VFile',
		props: {
			n: {
				type: String,
				required: true
			},
			v: {
				type: String,
				default: ""
			},
			a: {
				type: String,
				default: ""
			},
			nn: {
				type: String,
				required: true
			}
		}
	}
</script>
