<template>
	<div id="app" class="container">
		<header class="header-module row no-gutters justify-content-between mb-0">
			<div class="col ehoi-logo">
				<a :href='mandantHref[$store.getters.getAppMandant] ?? "https://www.e-hoi.de"' class="logo">
					<img class="img" :src="require('@/assets/images/'+(mandantLogo[$store.getters.getAppMandant] ?? 'website-logo_101.png'))" alt />
				</a>
			</div>
			<div class="col-auto tuv-logo text-left">
				<span class="hotline no-underline pro default" v-if="$store.getters.getAppMandant == 101">
					<span class="d-none d-lg-inline">
						<span>{{$t('personal_consulting')}}</span>
						<em>
							<i class="fa fa-phone"></i>
							<span class="phonenumber">+49 69 20456 - 788</span>
						</em>
						<a href="mailto:partnerprogramm@e-hoi.de" class="pro default">partnerprogramm@e-hoi.de</a>
					</span>
					<span class="d-inline d-lg-none mr-3">
						<i class="fa fa-phone"></i>
					</span>
				</span>
				<div class="hotline no-underline pro default" v-else-if="$store.getters.getAppMandant == 106">
					<span class="d-none d-lg-inline">
						<span>{{$t('personal_consulting')}}</span>
						<em>
							<i class="fa fa-phone"></i>
							<span class="phonenumber">010 28 23 800</span>
						</em>
						<a  href="mailto:sales@zeetours.nl" class="pro default">sales@zeetours.nl</a>
					</span>
					<span class="d-inline d-lg-none mr-3">
						<i class="fa fa-phone"></i>
					</span>
				</div>
				<div class="hotline no-underline pro default" v-else-if="[109,111,112].includes($store.getters.getAppMandant)">
					<span class="d-none d-lg-inline">
						<span>{{$t('personal_consulting')}}</span>
						<em>
							<i class="fa fa-phone"></i>
							<span class="phonenumber">91 542 76 63</span>
						</em>
						<a  href="mailto:comercial@unmundodecruceros.com" class="pro default">comercial@unmundodecruceros.com</a>
					</span>
					<span class="d-inline d-lg-none mr-3">
						<i class="fa fa-phone"></i>
					</span>
				</div>
			</div>
			<div class="col-auto d-flex">
				<Flags />
			</div>
			<div class="col-auto navbar-light">
				<button type="button" class="navbar-toggler collapsed d-block d-lg-none" data-toggle="collapse" aria-expanded="false"
					data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
					<i class="fa fa-bars"></i>
				</button>
			</div>
		</header>
		<router-view />
		<Footer />
	</div>
</template>

<script>
	import Flags from "./views/Flags.vue";
	import Footer from "./views/Footer.vue";
	import {mandantLogo, mandantHref, mandantAppTitle, mandantAppClass} from "./lib/mandant.js";

	export default {
		components: { Flags, Footer },
		data: () => {
			return {
				mandantLogo: mandantLogo,
				mandantHref: mandantHref
			}
		},
		created(){
			window.document.body.classList.add(mandantAppClass[this.$store.getters.getAppMandant] ?? "");
			window.document.title = mandantAppTitle[this.$store.getters.getAppMandant] ?? "partnerware"

			let favicon = document.getElementById("favicon")

			if([109,111].includes(this.$store.getters.getAppMandant)){
				favicon?.setAttribute("href", require('@/assets/images/icon_um.jpg'))

			}else if(this.$store.getters.getAppMandant === 112){
				favicon?.setAttribute("href", require('@/assets/images/icon_wc.png'))
			}
		},
	};
</script>
<style>
	header{
		background-color: #ffffff;
		padding: 1.25rem;
	}

	header .pro em {
		font-family: Arial, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 1.55em;
		margin: 0;
		display: block;
		color: #555;
	}

	header .pro span,
	header .pro a {
		color: #555;
		font-size: 1.1em;
		font-weight: 400;
		line-height: 1.55rem;
		margin: 0 5px 0 0;
	}

	header .logo img {
		height: 74px;
	}
	@media (max-width: 991px) {
		header{
			padding: .5rem;
		}
		header .logo img {
			height: 25px;
		}

		header .pro span,
		header .pro a {
			font-size: 1.75em;
		}
	}

	.tuv-logo {
		text-align: center;
	}

	.navmobile a {
		color: var(--bs-primary);
		display: block;
	}

	footer.login{
		max-width: 650px;
	}
</style>
