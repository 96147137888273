<template>
	<Layout>
		<div>
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<Warning /> <!-- warning modal for invalid form validation-->
			<div class="modal fade" id="confirmDataModal" tabindex="-1" role="dialog" aria-labelledby="confirmDataModalTitle" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="confirmDataModalTitle">{{$t('invaliddata')}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="OK">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-info" data-dismiss="modal">OK</button>
						</div>
					</div>
				</div>
			</div>

			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="content">
					<form class="my-form" @submit.prevent="saveReisebuero">
						<div>
							<FEC :v="$v.reisebuero.reisebuerotyp" :n="$t('travelagency')">
								<VSelect v-model="reisebuero.reisebuerotyp" :q="ref_kette.map(t => { return {value: t.value, text: t.texts.find(txt => txt.language == 'de').label || ''}; })" :v="$v.reisebuero.reisebuerotyp" :d="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.firma" :n="$t('company') + '*'">
								<VText v-model="reisebuero.firma" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.abteilung" :n="$t('department_adresse')">
								<VText v-model="reisebuero.abteilung" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.anrede" :n="$t('salutation') + ' *'">
								<VSelect class="col-12 col-md-6" v-model="reisebuero.anrede" :q="rerenderSelectOptions(ref_anrede)" :v="$v.reisebuero.anrede" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.titel" :n="$t('titel')">
								<VSelect class="col-12 col-md-6" v-model="reisebuero.titel" :q="rerenderSelectOptions(ref_titel)" :v="$v.reisebuero.titel" :d="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.fname" :n="$t('fname') + ' *'" :customText="$t('validation_alpha', [$t('fname')])">
								<VText v-model="reisebuero.fname" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.lname" :n="$t('lname') + ' *'" :customText="$t('validation_alpha', [$t('lname')])">
								<VText v-model="reisebuero.lname" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.strasse" :n="$t('street') + ' *'">
								<VText v-model="reisebuero.strasse" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.hausnummer" :n="$t('houseNr') + ' *'">
								<VText v-model="reisebuero.hausnummer" maxlength="10" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.ort" :n="$t('city') + ' *'">
								<VText v-model="reisebuero.ort" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.land" :n="$t('country') + ' *'">
								<VSelect v-model="reisebuero.land" :q="rerenderSelectOptions(ref_country)" :v="$v.reisebuero.land" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.plz" :n="$t('zipcode') + '*'">
								<VText v-model="reisebuero.plz" :required="true" />
							</FEC>
						</div>
						<div>
							<div class="row no-gutters">
								<div class="col-6 col-form-label">{{$t('phonenumber')}} *</div>
								<Telnumber v-model="reisebuero.telefon" :v="$v.reisebuero.telefon" :q="telnumbers" :required="true" :recomendedTels="recomTelnumbers"></Telnumber>
							</div>
						</div>
						<div>
							<FEC :v="$v.reisebuero.email" :n="$t('email') + '*'">
								<VText v-model="reisebuero.email" :required="true" />
								<div class="alert alert-info" v-if="$store.getters.isTradePartner">{{$t("email_infotext")}}</div>
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.url" :n="$t('homepage')">
								<VText v-model="reisebuero.url" />
							</FEC>
						</div>
						<div class="hr" />
						<div class="d-flex mb-2">
							<span class="span-style">{{$t('tracking')}}</span>
						</div>
						<div>
							<FEC :v="$v.reisebuero.url" :n="$t('google_tag_managerID')">
								<VText v-model="reisebuero.gtmid" maxlength="100" />
							</FEC>
						</div>
						<div class="hr" />
						<div>
							<FEC :v="$v.reisebuero.director" :n="$t('ceo')">
								<VText v-model="reisebuero.director" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.register" :n="$t('register') + '*'">
								<VText v-model="reisebuero.register" :required="true" />
							</FEC>
						</div>
						<div>
							<FEC :v="$v.reisebuero.steuernr" :n="$t('taxNumber') + '*'">
								<VText v-model="reisebuero.steuernr" :required="true" />
							</FEC>
						</div>
						<div v-if="(bankAccounts?.length ?? 0) > 0">
							<div class="hr" />
							<div class="d-flex mb-2">
								<span class="span-style">{{$t('bank_transfer')}}</span>
							</div>
							<div v-for="(bankAccount,index) in bankAccounts" :key="bankAccount.paymentaccountid">
								<div class="row no-gutters">
									<div class="col-6 col-form-label">{{ $t("no.") }} {{ index + 1 }}</div>
									<div class="col-6">
										<VCheckbox class="mx-auto" :label="$t('active')" v-model="bankAccount.active" />
									</div>
								</div>
								<div>
									<FEC :v="$v.bankAccounts.$each.$iter[index].bankname" n="Bank *">
										<VText v-model="bankAccount.bankname" :required="true" />
									</FEC>
								</div>
								<div>
									<FEC :v="$v.bankAccounts.$each.$iter[index].accountowner" :n="$t('bankholder') + ' *'">
										<VText v-model="bankAccount.accountowner" :required="true" />
									</FEC>
								</div>
								<div>
									<FEC :v="$v.bankAccounts.$each.$iter[index].iban" n="IBAN *">
										<VText v-model="bankAccount.iban" :required="(bankAccount.paymentaccountid ?? 0) === 0" :disabled="(bankAccount.paymentaccountid ?? 0) > 0"/>
									</FEC>
								</div>
								<div>
									<FEC :v="$v.bankAccounts.$each.$iter[index].swift" n="Swiftcode / BIC *">
										<VText v-model="bankAccount.swift" :required="true" />
									</FEC>
								</div>
								<div v-if="(bankAccount.paymentaccountid ?? 0) === 0">
									<button type="button" class="btn btn-danger" @click="deleteBankaccount(bankAccount)">
										<i class="fa fa-trash"></i> {{$t('delete')}}
									</button>
								</div>
								<div class="hr" v-if="index+1 < bankAccounts.length" />
							</div>
							<div>
								<button type="button" class="btn btn-success" @click="addBankaccount">
									<i class="fa fa-plus"></i> {{$t('add')}}
								</button>
							</div>
						</div>
						<div class="form-group row">
							<button type="submit" class="mx-auto btn btn-info" :disabled="bankAccounts.length > 0 && bankAccounts.every(b => !b.active)">
								<i class="fa fa-save"></i> {{$t('save')}}
							</button>
						</div>
						<div class="form-group row">
							<Feedback class="mx-auto" :successMessage="$t('data_saved')" />
						</div>
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	import Warning from "../views/Warning.vue";
	import Error from "../views/Error.vue";
	import Feedback from "../views/Feedback.vue";
	import cloneDeep from "lodash.clonedeep";
	import {
		required,
		minLength,
		numeric,
		helpers,
		alpha,
		email
	} from "vuelidate/lib/validators";
	import Vuex from "vuex";
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	//html elements
	import VText from "../views/htmlelements/VText.vue";
	import VSelect from "../views/htmlelements/VSelect.vue";
	import VCheckbox from '../views/htmlelements/VCheckbox.vue'
	import Telnumber from "../views/htmlelements/Telnumber.vue";
	import FEC from "../views/htmlelements/FormElementContainer.vue";
	import $ from "jquery";
	//abstract page
	import Page from "../pages/Page.vue";

	const isPattern = param => {
		return helpers.withParams({ type: "pattern", bsp: param.bsp }, value => {
			return !helpers.req(value) || value.match(param.pattern) !== null ? true : false
		});
	};

	const checkPatternIfNot = (prop, validator, bsp) =>
  helpers.withParams({ type: 'pattern', bsp: bsp }, function(value, parentVm) {
    return helpers.ref(prop, this, parentVm) ? true : validator(value)
  })

	//reisebuerio must be declared in data even though we later set it in getReisebuero or our validation won't be bound to it!
	export default {
		data() {
			return {
				reisebuero: cloneDeep(this.$store.state.reisebuero),
				telnumbers: this.$store.state.telnumbers,
				recomTelnumbers: [],
				ref_anrede: this.$store.state.stammdaten.salutation,
				ref_titel: this.$store.state.stammdaten.titel,
				ref_country: this.$store.state.stammdaten.country,
				ref_kette: this.$store.state.stammdaten.kette,
				bankAccounts: []
			};
		},
		components: {
			Error,
			Layout,
			SaveSpinner,
			Feedback,
			VText,
			VSelect,
			VCheckbox,
			FEC,
			Warning,
			Telnumber
		},
		computed: {
			...ms(["ui"])
		},
		mixins: [Page],
		methods: {
			debug: function(){
				console.log(this.$v.reisebuero)
				console.log(this.$v.bankAccount)
			},
			created: function() {},
			saveReisebuero: async function() {
				this.$v.$touch();
				this.$store.commit("resetui");
				if (this.$v.$invalid) {
					this.submitStatus = "ERROR";
					$('#confirmDataModal').modal();
				} else {
					let rb = cloneDeep(this.reisebuero);
					this.$store.commit("setui", { loadingflag: true });

					await this.$store.dispatch("savereisebuero", { rb: rb, ba: cloneDeep(this.bankAccounts) });
					this.reisebuero = cloneDeep(this.$store.state.reisebuero);
					this.bankAccounts = cloneDeep(this.$store.state.bankaccounts);

					this.$store.commit("setui", { loadingflag: false });
				}
			},
			getReisebuero: async function() {
				this.$store.commit("setui", { loadingflag: true });
				try {
					await this.$store.dispatch("getreisebuero", {});
					this.reisebuero = cloneDeep(this.$store.state.reisebuero)

					await this.$store.dispatch("getbackaccounts", {});
					this.bankAccounts = cloneDeep(this.$store.state.bankaccounts)
				} catch(err) {
					this.$store.commit("setui", {  errorflag: true })
				} finally{
					this.$store.commit("setui", { loadingflag: false })
				}

			},
			getTelnumbers: async function(){
				this.$store.commit("setui", { loadingflag: true });
				try {
					await this.$store.dispatch("listTelnumbers", {});
					let phonecodes = [];

					switch(this.$store.state.auth.mandant){
						case 104:
							phonecodes = ["BE","DE","NL"];
							break;
						case 105:
							phonecodes = ["BE","DE","NL"];
							break;
						case 106:
							phonecodes = ["BE","DE","NL"];
							break;
						case 107:
							phonecodes = ["BE","DE","NL"];
							break;
						case 109:
							phonecodes = ["ES"];
							break;
						case 111:
							phonecodes = ["PT"];
							break;
						case 112:
							phonecodes = ["FR"];
							break;
						default:
							phonecodes = ["AT","DE","CH"];
					}

					this.telnumbers = cloneDeep(this.$store.state.telnumbers)
					this.recomTelnumbers = this.telnumbers.filter(t => phonecodes.includes(t.countrycode));
				} catch(err) {
					this.$store.commit("setui", {  errorflag: true })
				} finally{
					this.$store.commit("setui", { loadingflag: false })
				}
			},
			addBankaccount: function(){
				this.bankAccounts.push({
					active: true,
					bankname: "",
					accountowner: "",
					iban: "",
					swift: ""
				});
			},
			deleteBankaccount: function(bankaccount){
				if((bankaccount.paymentaccountid ?? 0) == 0){
					this.bankAccounts = this.bankAccounts.filter(b => b != bankaccount);
				}
			},
			getRecomdendedTels: function(){
				this.recomTelnumbers
			},
			rerenderSelectOptions: function(data){
				return data.map(t => {
					return {value: t.value, text: t.texts.find(txt => txt.language == this.$i18n.locale)?.label || t.texts.find(txt => txt.language == "de")?.label || ''};
				}).sort((a,b) => {
					if(a.text.toLowerCase() > b.text.toLowerCase()){return 1}
					else if(a.text.toLowerCase() < b.text.toLowerCase()){return -1}
					return 0;
				});
			}
		},
		validations() {
			let plzPattern = {
				pattern: /^[0-9]{4,}$/,
				bsp: "1234"
			}
			let ibanPattern = {
				pattern: /^[A-Z]{2}[0-9]{18,}$/,
				bsp: "DE000011110000111100"
			}

			if([104,105,106,107].includes(this.$store.state.auth.mandant)){
				plzPattern = {
					pattern: /^[0-9]{4}[A-Za-z]{2}$/,
					bsp: "1234AB"
				};
				ibanPattern = {
					pattern: /^[0-9A-Z]{18,}$/,
					bsp: "NL000011110000111100"
				}
			}else if([109].includes(this.$store.state.auth.mandant)){
				ibanPattern = {
					pattern: /^[0-9A-Z]{18,}$/,
					bsp: "AA000011110000111100"
				}
			}else if([111].includes(this.$store.state.auth.mandant)){
				plzPattern = {
					pattern: /^[0-9]{4}-[0-9]{3}$/,
					bsp: "1234-123"
				};
				ibanPattern = {
					pattern: /^[0-9A-Z]{18,}$/,
					bsp: "PT000011110000111100"
				}
			}else if([112].includes(this.$store.state.auth.mandant)){
				ibanPattern = {
					pattern: /^[0-9A-Z]{18,}$/,
					bsp: "FR000011110000111100"
				}
			}

			return {
				reisebuero: {
					anrede: {
						required
					},
					firma: {
						required
					},
					fname: {
						pattern: isPattern({ pattern: /^[a-zA-ZÀ-ž]*$/, bsp: "A-Z" }),
						required
					},
					lname: {
						pattern: isPattern({ pattern: /^[a-zA-ZÀ-ž]*$/, bsp: "A-Z" }),
						required,
					},
					strasse: {
						required
					},
					ort: {
						required,
						alpha
					},
					hausnummer: {
						required,
						numeric
					},
					plz: {
						required,
						pattern: isPattern(plzPattern),
						minLength: minLength(4)
					},
					telefon: {
						required,
						pattern: isPattern({ pattern: /^\+\d{2,}((?:\s\d+)+)$/g, bsp: "" })
					},
					titel: {
						numeric
					},
					email: {
						required,
						email
					},
					register: {
						required
					},
					steuernr: {
						required
					},
					land: {
						required
					},
					reisebuerotyp: {
						numeric
					}
				},
				bankAccounts: {
					$each: {
						bankname: {
							required
						},
						accountowner: {
							required,
							pattern: isPattern({ pattern: /^[a-zA-ZÀ-ž].*[a-zA-ZÀ-ž]$/, bsp: "A-Z" })
						},
						iban: {
							required,
							pattern: checkPatternIfNot("paymentaccountid", isPattern(ibanPattern), ibanPattern.bsp)
						},
						swift: {
							required,
							pattern: isPattern({ pattern: /^[A-Z]{6}[0-9A-Z]{5}$/, bsp: "AAAABBCC123" })
						}
					}
				}
			}
		},
		//I am executed everytime this component is intialized.
		async created() {
			if((Object.keys(this.$store.state.stammdaten).length===0 || typeof this.$store.state.stammdaten !== 'object') && (this.$store.state.auth.status===1)){
				await this.$store.dispatch('getstammdaten')
			}

			this.ref_anrede = this.$store.state.stammdaten.salutation;
			this.ref_titel = this.$store.state.stammdaten.titel;
			this.ref_country = this.$store.state.stammdaten.country;
			this.ref_kette = this.$store.state.stammdaten.kette;

			await this.getTelnumbers();
			await this.getReisebuero(); //loads the partner data
		}
	};
</script>
