<template>
	<div>
		<Subnav v-if="subhead===true" />
		<main class="my-view">
			<transition class="col-sm" name="pageview" mode="out-in">
				<slot />
			</transition>
		</main>
	</div>
</template>
<style>
	.pageview-enter-active,
	.pageview-leave-active {
		transition: opacity 1.5s ease-in-out, transform 1.5s ease;
	}

	.pageview-enter,
	.pageview-leave-to {
		opacity: 0;
	}

	.pageview-enter-to,
	.pageview-leave {
		opacity: 1;
	}

	main{
		padding-top: .5rem;
	}
</style>

<script>
	import Subnav from "../views/Subnav.vue";

	export default {
		props: {
			subhead: { default: true, type: Boolean }
		},
		components: { Subnav }
	};
</script>


