<template>
	<Layout>
		<div>
			<SaveSpinner :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false" class="content">
				<div class="row">
					<div class="legende mx-auto">
						<p><b>Legende:</b></p>
						<div>
							<div>Visits: {{$t('report_visits')}}</div>
							<div>BUA: {{$t('report_bua')}}</div>
							<div>BU: {{$t('report_bu')}}</div>
							<div>StBu: {{$t('report_stBu')}}</div>
							<div>BUA / Visits: {{$t('report_bua_visits')}}</div>
							<div>BU / Visits: {{$t('report_bu_visits')}}</div>
							<div>PAX: {{$t('report_pax')}}</div>
							<div>UmsatzGes: {{$t('report_umsatzges')}}</div>
							<div>UmsatzPAX: {{$t('report_umsatzpax')}}</div>
							<div> {{$t('report_change')}}: {{$t('report_change_legende')}}</div>
						</div>
					</div>
				</div>
				<div>
					<form class="my-form" @submit.prevent="listPartnerStatistics">
						<FEC n="Datum Filtertyp">
							<label class="radio mr-4 py-2">
								<span class="pr-1">{{$t('bookingdate')}}</span>
								<input type="radio" name="searchin" value="1" class="check-box" v-model="filter.bookingPeriodType" />
							</label>
							<label class="radio">
								<span class="pr-1">{{$t('departure')}}</span>
								<input type="radio" name="searchin" value="2" class="check-box" v-model="filter.bookingPeriodType" />
							</label>
						</FEC>
						<FEC n="Filtern nach">
							<VSelect v-model="filter.groupType" :q="groupFilterOptions" :value="filter.groupType" :v="{}"></VSelect>
						</FEC>
						<FEC :n="$t('dateRange')">
							<div>
								<VSelect v-model="filter.datePeriodType" :q="dateFilterOptions" :value="filter.datePeriodType" :v="{}"></VSelect>
							</div>
							<div class="pt-1">
								<div class="row">
									<div class="col-6">
										<v-date-picker v-model="filter.startDate" :max-date="filter.endDate">
											<template v-slot="{ inputValue, inputEvents }">
													<input
														class="form-control"
														:value="inputValue"
														v-on="inputEvents"
													/>
												</template>
										</v-date-picker>
									</div>
									<div class="col-6">
										<v-date-picker v-model="filter.endDate" :min-date="filter.startDate">
											<template v-slot="{ inputValue, inputEvents }">
													<input
														class="form-control"
														:value="inputValue"
														v-on="inputEvents"
													/>
												</template>
										</v-date-picker>
									</div>
								</div>
							</div>
						</FEC>
						<button type="submit" class="mx-auto btn btn-info d-block">{{$t('refresh')}}</button>
					</form>
				</div>
				<div class="py-2">
					<div class="overflow-auto fixed-table-height">
						<table class="text-right mx-auto">
							<thead class="font-weight-bold">
								<tr>
									<td colspan="12">
										<div class="d-flex">
											<button type="button" class="btn btn-outline-info" @click="exportReport">
												<i class="fa fa-file-excel-o"></i>
											</button>
											<div class="flex-grow-1"></div>
										</div>
									</td>
								</tr>
								<tr>
									<th></th>
									<th>{{$t('year')}}</th>
									<th>{{$t('month')}}</th>
									<th>BUA</th>
									<th>BU</th>
									<th>StBu</th>
									<th>PAX</th>
									<th>UmsatzGes</th>
									<th>UmsatzPAX</th>
									<th class="white-space-nowrap">{{$t('report_change')}}</th>
								</tr>
							</thead>
							<tbody v-for="(item, index) in partnerstatistics.statistic" :key="item.jahr.toString() + item.monat.toString()">
								<tr :class="index % 2 > 0 ? 'even' : 'odd'">
									<td>
										<label v-if="item.groupedItems.length > 0">
											<i class="fa fa-plus"></i>
											<button type="button" hidden @click="item.hidden = !item.hidden"></button>
										</label>
									</td>
									<td>{{item.jahr}}</td>
									<td>{{item.monat}}</td>
									<td>{{item.bookingrequest_count.toLocaleString()}}</td>
									<td>{{item.booking_count.toLocaleString()}}</td>
									<td>{{item.stornos.toLocaleString()}}</td>
									<td>{{item.personen}}</td>
									<td>{{item.bookinggesamt_sum.toLocaleString(undefined,{style: 'currency', currency: 'EUR'})}}</td>
									<td>{{printDecimal(item.bookinggesamt_sum !== 0 && item.personen !== 0 ? item.bookinggesamt_sum / item.personen : 0)}} €</td>
									<td>{{(calculateMonthDiff(item, partnerstatistics.statistic[index+1]) * 100).toFixed(2).toString()}}%</td>
								</tr>
								<tr v-for="(group) in item.groupedItems" :key="group.shippingcompanyid" :hidden="item.hidden" :class="index % 2 > 0 ? 'even' : 'odd'">
									<td colspan="3">{{displayText(group, $i18n.locale)}}</td>
									<td>{{group.bookingrequest_count.toLocaleString()}}</td>
									<td>{{group.booking_count.toLocaleString()}}</td>
									<td>{{group.stornos.toLocaleString()}}</td>
									<td>{{group.personen}}</td>
									<td>{{group.bookinggesamt_sum.toLocaleString(undefined,{style: 'currency', currency: 'EUR'})}}</td>
									<td>{{printDecimal(group.bookinggesamt_sum !== 0 && group.personen !== 0 ? group.bookinggesamt_sum / group.personen : 0)}} €</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div>
					<table class="text-right mx-auto table-striped-even">
						<thead>
							<tr>
								<th>Zeitraum</th>
								<th>Visits</th>
								<th class="white-space-nowrap">BUA / Visits</th>
								<th class="white-space-nowrap">BU / Visits</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item) in partnerstatistics.statistic" :key="item.jahr + item.monat">
								<td>{{$t('month_' + item.monat)}} {{ item.jahr }}</td>
								<td>{{item.visits.toLocaleString()}}</td>
								<td>{{printDecimal(item.bookingrequest_count !== 0 && item.visits !== 0 ? item.bookingrequest_count / item.visits * 100 : 0)}} %</td>
								<td>{{printDecimal(item.booking_count !== 0 && item.visits !== 0 ? item.booking_count / item.visits * 100 : 0)}} %</td>
							</tr>

						</tbody>
					</table>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import {saveAs} from 'file-saver';
	import writeXlsxFile from 'write-excel-file';
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	import Error from "../views/Error.vue";

	import Vuex from "vuex";
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	import cloneDeep from "lodash.clonedeep";

	//abstract page
	import Page from "../pages/Page.vue";
	import VSelect from '../views/htmlelements/VSelect.vue';
	import FEC from "../views/htmlelements/FormElementContainer.vue";

	export default {
		data() {
			return {
				/** @type {BookingStatisticReturnData} */
				partnerstatistics: cloneDeep(this.$store.state.partnerstatistics),
				/** @type {PartnerStatisticFilter} */
				filter: {
					bookingPeriodType: 1,
					groupType: 1,
					datePeriodType: "year"
				},
				dateFilterOptions: [],
				groupFilterOptions: [],
				shippingCompanies: [],
				cruisingAreas: []
			};
		},
		computed: {
			...ms(["ui"])
		},
		components: { Error, Layout, SaveSpinner, FEC, VSelect },
		mixins: [Page],
		methods: {
			listPartnerStatistics: async function() {
				this.$store.commit("setui", { loadingflag: true });

				let clone = cloneDeep(this.filter)

				if(this.filter.datePeriodType !== "custom"){
					clone.startDate = undefined
					clone.endDate = undefined
				}

				try {
					await this.$store.dispatch("getpartnerstatistics", clone);
					this.partnerstatistics = cloneDeep(this.$store.state.partnerstatistics);
				} catch {
					this.$store.commit("setui", {  errorflag: true });
				}finally{
					this.$store.commit("setui", { loadingflag: false });
				}
			},
			/** 
			 * @param {BookingStatistic} item 
			 * @param {string} locale 
			 * */
			displayText: function(item, locale){
				if(item.shippingcompanyid > 0){
					return this.partnerstatistics.shippingcomypanytexts.find(t => t.shippingcompanyid === item.shippingcompanyid && t.language === locale)?.name
					?? this.partnerstatistics.shippingcomypanytexts.find(t => t.shippingcompanyid === item.shippingcompanyid && t.language === "de")?.name ?? ""
				}else if(item.cruisingareaid > 0){
					return this.partnerstatistics.cruisingareatexts?.find(t => t.cruisingareaid === item.cruisingareaid && t.language === locale)?.name
					?? this.partnerstatistics.cruisingareatexts?.find(t => t.cruisingareaid === item.cruisingareaid && t.language === "de")?.name ?? ""
				}

				return ""
			},
			/** 
			 * @param {BookingStatistic} item 
			 * @param {BookingStatistic} [prevItem] 
			 * */
			calculateMonthDiff: function(item, prevItem) {
				let umbook = item.bookinggesamt_sum;
				let prevUmbook = prevItem?.bookinggesamt_sum ?? 0

				if(prevUmbook == 0 && umbook > 0){
					return 0;
				}else if(prevUmbook == 0 && umbook == 0){
					return prevUmbook;
				}

				return (umbook - prevUmbook) / prevUmbook;
				// return ((umbook - prevUmbook) / prevUmbook * 100).toFixed(2).toString();
			},
			/** @param integer {number} */
			printDecimal: function(integer, place=2){
				return integer.toLocaleString(undefined,{minimumFractionDigits: place, maximumFractionDigits: place});
			},
			/** @param date {Date} */
			formatDate: function (date, mask = "yyyy-mm-dd"){
				const month = date.getMonth() + 1;
				const day = date.getDate();

				mask = mask.replace(/yyyy/, date.getFullYear().toString());
				mask = mask.replace(/mm/, month.toString().padStart(2, "0"));
				mask = mask.replace(/dd/, day.toString().padStart(2, "0"));

				return mask;
			},
			/** 
			 * @param {BookingStatistic} item 
			 * */
			findPrevItem: function(item) {
				let index = this.partnerstatistics.statistic.map(s => s.jahr.toString() + "-" + s.monat.toString()).indexOf(item.jahr.toString() + "-" + item.monat.toString())

				return this.partnerstatistics.statistic[index+1];
			},
			exportReport: async function(){
				const schema = [
					{column: this.$t('year'), type: Number, value: item => item.jahr, width: 8, align: "right"},
					{column: this.$t('month'), type: Number, value: item => item.monat, width: 7, align: "right"},
					{column: "Visits", type: Number, format: "#,##0", value: item => item.visits, width: 13, align: "right"},
					{column: "BUA", type: Number, format: "#,##0", value: item => item.bookingrequest_count, width: 10, align: "right"},
					{column: "BU", type: Number, format: "#,##0", value: item => item.booking_count, width: 10, align: "right"},
					{column: "stBu", type: Number, format: "#,##0", value: item => item.stornos, width: 10, align: "right"},
					{column: "BUA / Visits", type: Number, format: "0.00%", value: item => item.bookingrequest_count !== 0 && item.visits !== 0 ? item.bookingrequest_count / item.visits * 100 : 0, width: 11, align: "right"},
					{column: "BU / Visits", type: Number, format: "0.00%", value: item => item.booking_count !== 0 && item.visits !== 0 ? item.booking_count / item.visits * 100 : 0, width: 11, align: "right"},
					{column: "PAX", type: Number, value: item => item.personen, width: 10, align: "right"},
					{column: "UmsatzGes", type: Number, format:"#,##0.00 €", value: item => item.bookinggesamt_sum, width: 15, align: "right"},
					{column: "UmsatzPAX", type: Number, format:"#,##0.00 €", value: item => item.bookinggesamt_sum !== 0 && item.personen !== 0 ? item.bookinggesamt_sum / item.personen : 0, width: 14, align: "right"},
					{column: this.$t('report_change'), type: Number, format: "0.00%", value: item => this.calculateMonthDiff(item, this.findPrevItem(item)), width: 17, align: "right"}
				];
				let filenamePrefix = "ehoi"
				switch(this.$store.state.auth.mandant){
					case 104:
					filenamePrefix = "cruisewinkel"
					break;
					case 105:
					filenamePrefix = "wondercruises"
					break;
					case 106:
					filenamePrefix = "zeetours"
					break;
					case 107:
					filenamePrefix = "cruisetravel"
					break;
					case 109:
					filenamePrefix = "ummundo"
					break;
					case 111:
					filenamePrefix = "ummundo"
					break;
					case 112:
					filenamePrefix = "wondercruises"
					break;
				}
				let filename = `${filenamePrefix}-partnerreport-${this.formatDate(new Date())}.xlsx`;
				// let alteredStatistics = this.partnerstatistics.statistic.flatMap(s => [s, ...s.groupedItems])
				// andere mandanten reinbringen
				// console.log(alteredStatistics)
				let fileToExport = await writeXlsxFile(this.partnerstatistics.statistic, {schema});
				saveAs(fileToExport, filename);
			}
		},

		//I am executed everytime this component is intialized.
		created() {
			this.listPartnerStatistics(); //loads the partner data

			this.dateFilterOptions = [
				{"text" : this.$t("this_month") , "value":"month" },
				{"text" : this.$t("this_year") , "value":"year" },
				{"text" : this.$t("customized") , "value":"custom" }
			]
			this.groupFilterOptions = [
				{"text" : this.$t("shippingcompany") , "value":1 },
				{"text" : this.$t("cruisingarea") , "value":2 }
			]
		}
	};
</script>
<style>
	table tr.jahresSumme{
		background-color: var(--bs-primary);
		color: #fff;
	}

	tfoot{
		border-top: solid 3px #fff;
		background-color: var(--bs-primary);
		color: #fff;
	}

	table thead{
		position: sticky;
		top: 0;
	}

	.legende{
		display: inline-block;
		color: white;
		background-color: var(--bs-primary);
		border-top-left-radius: 30px;
		border-bottom-right-radius: 30px;
		margin: .5rem 0;
		padding: 1rem;
	}
	.white-space-nowrap{
		white-space: nowrap;
	}

	.fixed-table-height{
		max-height: 700px;
	}
</style>
