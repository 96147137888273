<template>
	<Layout>
		<div>
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div class="content">
				<form class="my-form" @submit.prevent="savePassword">
					<div>
						<FEC :v="$v.passwordchange.oldpassword" :n="$t('password_old')">
							<VPassword v-model="passwordchange.oldpassword" />
						</FEC>
					</div>
					<div>
						<FEC :v="$v.passwordchange.password1" :n="$t('password_new')">
							<VPassword v-model="passwordchange.password1" />
						</FEC>
					</div>
					<div>
						<FEC :v="$v.passwordchange.password2" :n="$t('password_confirm')" :r="$t('password_new')">
							<VPassword v-model="passwordchange.password2" />
						</FEC>
					</div>
					<div class="form-group row">
						<button type="submit" class="mx-auto btn btn-info"><i class="fa fa-save"></i> {{$t('save')}}</button>
					</div>
					<div class="form-group row">
						<Feedback class="mx-auto" :successMessage="$t('login_changed')" />
					</div>
				</form>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import cloneDeep from "lodash.clonedeep";
	import SaveSpinner from "../views/Spinner.vue";
	import Feedback from "../views/Feedback.vue";
	import { required,minLength, helpers, sameAs } from "vuelidate/lib/validators";
	import Vuex from "vuex";
	//import { mapMutations } from 'vuex'
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	//html elements
	import VPassword from "../views/htmlelements/VPassword.vue";
	import FEC from "../views/htmlelements/FormElementContainer.vue";
	//abstract page
	import Page from "../pages/Page.vue";

	const isPassword = () =>
		helpers.withParams({}, value =>
			!helpers.req(value) || value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{4,}$/) !== null ? true : false
		);

	export default {
		data() {
			return {
				passwordchange: cloneDeep(this.$store.state.passwordchange)
			};
		},
		components: { Layout, SaveSpinner, Feedback, VPassword, FEC },
		mixins: [Page],
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},
			...ms(["ui"])
		},
		methods: {
			savePassword: async function() {
				this.$v.$touch();
				this.$store.commit("resetui");
				if (this.$v.$invalid) {
					this.$store.commit("adduierror", {
						errorflag: true,
						feedback: "missing_required_fields"
					});
				} else {
					let pc = cloneDeep(this.passwordchange);
					await this.$store.dispatch("passwordchange", { pc: pc });
					this.passwordchange = cloneDeep(this.$store.state.passwordchange); //sets local clone to match the changed values
					this.$store.dispatch("clearpassworddata", {}); //removes the values from the store since it's a security risk
				}
			}
		},
		validations: {
			passwordchange: {
				oldpassword: {
					required
				},
				password1: {
					required,
					minLength: minLength(8),
					password: isPassword()
				},
				password2: {
					required,
					sameAs: sameAs(function(){return this.passwordchange.password1})
				}
			}
		},
		//I am executed everytime this component is intialized.
		mounted() {
			//resetui()
		}
	};
</script>