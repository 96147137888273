<template>
	<Layout>
		<div class="content">
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="" v-if="organizers.length > 0">
					<form @submit.prevent="saveOrganizer" class="OrganizerForm">
						<fieldset v-for="(organizer,index) in organizers" :key="organizer.organizerID">
							<legend>{{organizer.organizername}}</legend>
							<div class="row">
								<div class="col-12 col-md-6 col-lg-4">
									<div class="input-group">
										<label class="radio border-ehoi" v-bind:class="{checked: organizer.agenturoptionnr==0}">
											<span>{{$t('none')}}</span>
											<input type="radio" name="optionNr" :value="0" v-model="organizer.agenturoptionnr" hidden />
										</label>
										<label class="radio border-ehoi" v-bind:class="{checked: organizer.agenturoptionnr==1}">
											<span>{{$t('agencynum')}}</span>
											<input type="radio" name="optionNr" :value="1" v-model="organizer.agenturoptionnr" hidden />
										</label>
										<label class="radio border-ehoi" v-bind:class="{checked: organizer.agenturoptionnr==2}">
											<span>{{$t('collection_agency')}}</span>
											<input type="radio" name="optionNr" :value="2" v-model="organizer.agenturoptionnr" hidden />
										</label>
									</div>
									<template v-if="organizer.agenturoptionnr == 1">
										<FEC :n="$t('agencynum')">
											<VText v-model="organizer.agenturnrlevel1" />
										</FEC>
									</template>
									<template v-if="organizer.agenturoptionnr == 2">
										<FEC :n="$t('collection_agency')">
											<VText v-model="organizer.agenturnrlevel1" />
										</FEC>
										<FEC :n="$t('subagency')">
											<VText v-model="organizer.agenturnrlevel2" />
										</FEC>
									</template>
								</div>
								<div class="col-12 col-md-6 col-lg-6">
									<FEC :v="$v.organizers.$each.$iter[index].reservierungtel" :n="$t('phonenumber')">
										<VText v-model="organizer.reservierungtel" />
									</FEC>
									<FEC :v="$v.organizers.$each.$iter[index].reservierungfax" :n="$t('fax')">
										<VText v-model="organizer.reservierungfax" />
									</FEC>
									<FEC :v="$v.organizers.$each.$iter[index].reservierungemail" :n="$t('email')"> 
										<VText v-model="organizer.reservierungemail" />
									</FEC>
								</div>
								<div class="col-12 col-md-6 col-lg-2">
									<div>
										<VCheckbox class="mx-auto" :label="$t('inkasso')" v-model="organizer.inkasso" />
									</div>
									<div>
										<VCheckbox class="mx-auto" :label="$t('include')" v-model="organizer.aufnehmen" />
									</div>
								</div>
							</div>
						</fieldset>
						<div class="text-center">
							<button type="submit" class="btn btn-info">
								<i class="fa fa-save"></i> {{$t('save')}}
							</button>
						</div>
					</form>
				</div>
				<div v-if="organizers.length == 0">
					<p class="alert alert-warning">Keine Organizer vorhanden</p>
				</div>
			</div>
			<Feedback :successMessage="$t('data_saved')" />
		</div>
	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	import Feedback from "../views/Feedback.vue";
	import cloneDeep from "lodash.clonedeep";
	import Vuex from "vuex";
	//validators
	import {  email, helpers } from 'vuelidate/lib/validators'
	//html elements
	import VText from '../views/htmlelements/VText.vue'
	import VCheckbox from '../views/htmlelements/VCheckbox.vue'
	//import VSelect from '../views/htmlelements/VSelect.vue'
	import FEC from '../views/htmlelements/FormElementContainer.vue'
	//abstract page
	import Page from "../pages/Page.vue";
	//import { mapMutations } from 'vuex'
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	const isPattern = param =>
		helpers.withParams({ type: "pattern", bsp: param.bsp }, value =>
			!helpers.req(value) || value.match(param.pattern) !== null ? true : false
		);

	export default {
		data() {
			return {
				organizers: cloneDeep(this.$store.state.organizers)
			};
		},
		components: { Layout, SaveSpinner, Feedback, FEC, VCheckbox, VText },
		mixins: [Page],
		methods: {
			saveOrganizer: async function() {
				this.$v.$touch();
				this.$store.commit("resetui");
				if (this.$v.$invalid) {
					this.submitStatus = "ERROR";
					//console.log("invalid data!");
				} else {
					let org = cloneDeep(this.organizers);
					this.$store.commit("setui", { loadingflag: true });
					await this.$store.dispatch("saveorganizer", { org: org });
					this.organizers = cloneDeep(this.$store.state.organizers);
					this.$store.commit("setui", { loadingflag: false });
				}
			},
			listOrganizer: async function() {
				this.$store.commit("setui", { loadingflag: true });
				try {
						await this.$store.dispatch("listorganizer", {});
						this.organizers = cloneDeep(this.$store.state.organizers);
					} catch {
						this.$store.commit("setui", {  errorflag: true });
					} finally{
						this.$store.commit("setui", { loadingflag: false })
					}
			}
		}, //end methods
		validations: {
			organizers:{
				$each: {
					reservierungtel: {
						pattern: isPattern({pattern: /^\+?[0-9\- ()]+\d+$/, bsp: '+49 00 000'})
					},
					reservierungfax: {
						pattern: isPattern({pattern: /^\+?[0-9\- ()]+\d+$/, bsp: '+49 00 000'})
					},
					reservierungemail: {
						email
					}
				}
			}
		},
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},

			...ms(["ui"])
		},
		//I am executed everytime this component is intialized.
		created() {
			this.listOrganizer();
		}
	};
</script>

<style>
	.OrganizerForm input{
		font-size: .95rem;
	}

	.OrganizerForm input[type=radio]{
		width: auto;
	}

	.OrganizerForm fieldset{
		border: solid 1px var(--bs-primary);
		border-radius: 8px;
		padding: 0 .375rem;
		margin: .5rem 0;
	}

	.OrganizerForm legend{
		width: auto;
		margin-left: .5rem;
		font-size: 1.3rem;
	}
</style>