export default class configText{
	partnerid;
	languageid;
	emailfrom = "";
	emailcontact = "";
	emailfootertext = "";
	emailinformationtext = "";
	werbetext = "";

	constructor(partnerId, languageId){
		this.partnerid = partnerId;
		this.languageid = languageId;
	}
}