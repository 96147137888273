// @ts-check

/** 
 * @param {Blob} blob 
 * @param {string} name 
 * @returns {void}
*/
export function downloadFile(blob, name){
	
	let a = document.createElement("a");
	let url = URL.createObjectURL(blob);

	a.href = url;
	a.download = name;
	a.click();
	setTimeout(function() {
		window.URL.revokeObjectURL(url);
	}, 0);
}