<template>
	<div class="row">
		<div class="col-md-6">
			<TopSortEdit :areaID="1" :q="q" :topSort="topSortHochsee" />
		</div>
		<div class="col-md-6">
			<TopSortEdit :areaID="2" :q="q" :topSort="topSortFluss" />
		</div>
	</div>
</template>

<script>

import TopSortEdit from "./TopSortEdit.vue";
import Vuex from "vuex";

const ms = Vuex.mapState;

export default {
	props:{
		q:{
			type: String,
			required: true
		},
		topSortHochsee:{
			type: Object,
			required: true
		},
		topSortFluss:{
			type: Object,
			required: true
		}
	},
	computed: {
		...ms(["ui"])
	},
	components: { TopSortEdit },
	methods: {
	}
}
</script>