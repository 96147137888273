<template>
	<Layout>
		<div class="content">
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="row">
					<div class="col-3">
						<div class="card">
							<div class="p-1">
								<div v-for="bereich in bereiche" :key="bereich">
									<label>
										<input type="radio" name="templateBereich" :value="bereich" v-model="picked" v-on:change="bereichChanged(bereich)" />
										{{ bereich }}
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-9">
						<div v-if="banners.some(t => t.title.toLowerCase() === ('banner-'+this.picked.toLowerCase()))">
							<div>
								<img :src="displayImage()" alt="" />
							</div>
							<div>
								<button type="button" class="btn" @click="downloadBanner()">
									<i class="fa fa-download"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../layouts/Layout.vue";
	import Error from "../../views/Error.vue";
	import SaveSpinner from "../../views/Spinner.vue";
	import cloneDeep from "lodash.clonedeep";
	import Vuex from "vuex";
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	
	import Page from "../../pages/Page.vue";
	import { downloadFile } from "../../lib/file";

	export default {
		data(){
			return {
				/** @type Banner[] */
				banners: cloneDeep(this.$store.state.banner ?? []),
				pendingLoad: false,
				/** @type Map<number, Blob> */
				loadedBanner: new Map(),
				picked: "",
				bereiche:[
					"Afrika",
					"Alaska",
					"Asien",
					"Hawaii",
					"Karibik",
					"Mittelmeer",
					"Nordland",
					"Nordamerika",
					"Orient u. Emirate",
					"Fluss",
					"Sonstige"
				]
			}
		},
		components: {
			Layout,
			Error,
			SaveSpinner,
		},
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},
			...ms(["ui"])
		},
		mixins: [Page],
		methods: {
			displayImage(){
				/** @type Partial<Banner> */
				const banner = this.banners.find(t => t.title.toLowerCase() === ('banner-'+this.picked.toLowerCase())) ?? {};

				if(banner){
					return `data:image/${banner?.name?.split(".").at(-1) ?? "jpg"};base64, ${banner?.base64data}`;
				}else{
					return ""
				}
			},
			/** @param {string} bereich */
			bereichChanged(bereich){
				this.loadedBanner.set(bereich, undefined);
			},
			listBanner: async function (){
				this.$store.commit("setui", { loadingflag: true });
				try {
					await this.$store.dispatch("listBanner", {});

					this.banners = cloneDeep(this.$store.state.banner ?? []);
				} catch {
					this.$store.commit("setui", {  errorflag: true });
				} finally{
					this.$store.commit("setui", { loadingflag: false })
				}
			},
			/** @param {number} fileId */
			downloadBanner() {
				this.pendingLoad = true;
				/** @type Partial<Banner> */
				const banner = this.banners.find(t => t.title.toLowerCase() === ('banner-'+this.picked.toLowerCase())) ?? {};

				this.$store.dispatch("downloadBanner", {fileId: banner?.fileid ?? 0})
				.then((blob) => {
					downloadFile(blob, "banner-"+this.picked+"."+(banner.name.split(".").at(-1) ?? "jpg"));
					
					this.pendingLoad = false
				})
				.catch(() => {
					this.pendingLoad = false
				});
			}
		},
		created(){
			this.listBanner();
		}
	};
</script>