<template>
	<textarea class="form-control" v-model="areavalue" rows="3"></textarea>
</template>

<script>
    export default {
        name:'VArea',
				props:{
					value:{
						type: [String],
						required:true
					},
				},
        computed:{
            areavalue:{
                get(){ //the component renders synchronously, before our ajax is complete. the value will initially be undefined. Make sure we handle this.
                    if(typeof this.value === 'undefined'){
                      return ''
                    } 
                    else {
                      return this.value
                    }
                },
                set(v){

                    this.$emit('input',v);
                },
            },
				}
    }
</script>