<template>
	<div>
		<SaveSpinner  :loadFlag="ui.loadingflag" />
		<div v-if="error">
			<div class="alert alert-danger">
				<Error />
			</div>
		</div>
		<div v-else-if="loadingflag === false">
			<div class="content">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item">
						<a class="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">{{$t('userdata')}}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="rights-tab" data-toggle="tab" href="#rights" role="tab" aria-controls="rights" aria-selected="false">{{$t('userrights')}}</a>
					</li>
				</ul>
				<form class="mt-3 my-form" @submit.prevent="savePartnerUser" autocomplete="off">
					<div class="tab-content" id="myTabContent">
						<div class="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
							<div>
								<FEC :v="$v.partneruser.firstname" :n="$t('fname')">
									<VText v-model="partneruser.firstname" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.lastname" :n="$t('lname')">
									<VText v-model="partneruser.lastname" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.phone" :n="$t('phonenumber')">
									<VText v-model="partneruser.phone" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.fax" :n="$t('fax')">
									<VText v-model="partneruser.fax" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.email" :n="$t('email')">
									<VText v-model="partneruser.email" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.comment" :n="$t('comment')">
									<VText v-model="partneruser.comment" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.department" :n="$t('department')">
									<VText v-model="partneruser.department" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.username" :n="$t('username')">
									<VText v-model="partneruser.username" :readonly="!changeableName" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.partneruser.password" :n="$t('password')">
									<VPassword v-model="partneruser.password" :autocomplete="'new-password'" />
								</FEC>
							</div>
							<div>
								<FEC :v="$v.passwordConfirm" :n="$t('confirm')" :r="$t('password')">
									<VPassword v-model="passwordConfirm" />
								</FEC>
							</div>
						</div>
						<div class="tab-pane fade" id="rights" role="tabpanel" aria-labelledby="rights-tab">
							<div v-for="(acl) in partneruser.acl" :key="acl.acl">
								<FEC :n="$t(acl.acl)">
									<VCheckbox v-model="acl.active" />
								</FEC>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<button type="submit" class="mx-auto btn btn-info">
							<i class="fa fa-save"></i> {{$t('save')}}
						</button>
					</div>
					<div class="form-group row">
						<Feedback class="mx-auto" :successMessage="$t('data_saved')" />
					</div>
					<div class="form-group row">
						<Feedback class="mx-auto" :successMessage="$t('user_saved')" />
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import SaveSpinner from "../views/Spinner.vue";
	import Error from "../views/Error.vue";
	import Feedback from "../views/Feedback.vue";
	import cloneDeep from "lodash.clonedeep";
	import Vuex from "vuex";
	//validators
	import { required, helpers, email, minLength, numeric} from "vuelidate/lib/validators";
	//dynamic validation
	import runner from "../runners/axiosrunner";
	//html elements
	import VText from "../views/htmlelements/VText.vue";
	import VPassword from "../views/htmlelements/VPassword.vue";
	import VCheckbox from "../views/htmlelements/VCheckbox.vue";
	import FEC from "../views/htmlelements/FormElementContainer.vue";
	//abstract page
	import Page from "../pages/Page.vue";
	//import { mapMutations } from 'vuex'
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	const isPassword = () =>
		helpers.withParams({}, value =>
			!helpers.req(value) || value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{4,}$/) !== null ? true : false
		);

	export default {
		props: {
			partneruser: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				passwordConfirm: "",
				changeableName: false
			};
		},
		components: { SaveSpinner, Error, Feedback, VCheckbox, FEC, VText, VPassword },
		mixins: [Page],
		methods: {
			savePartnerUser: async function() {

				this.$v.$touch();
				this.$store.commit("resetui");
				if (this.$v.$invalid) {
					this.submitStatus = "ERROR";
					//console.log("invalid data!");
				} else {
					let pu = cloneDeep(this.partneruser);
					this.$store.commit("setui", { loadingflag: true });
					await this.$store.dispatch("savepartneruser", { pu: pu })
					.then((resp) => {
						this.partneruser.userno = resp.data.userno;
						this.partneruser.password = ""
						this.passwordConfirm = ""
						this.changeableName = this.partneruser.userno <= 0 || this.partneruser.username.length < 8;
						this.$emit("save", this.partneruser.userno)
					});
					this.$store.commit("setui", { loadingflag: false });
				}
			}
		},
		created(){
			this.changeableName = this.partneruser.userno <= 0 || this.partneruser.username.length < 8
		},
		//end methods
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},
			error() {
				return this.$store.state.ui.errorflag;
			},
			loadingflag() {
				return this.$store.state.ui.loadingflag;
			},
			...ms(["ui"])
		},
		validations(){
			let passwordValidations = {
				minLength: minLength(8),
				password: isPassword()
			}

			if(this.partneruser.userno <= 0){
				passwordValidations = {
					required,
					minLength: minLength(8),
					password: isPassword()
				}
			}

			return {
				partneruser: {
					username: {
						required,
						minLength: minLength(8),
						async mustBeUnique(value) {
							if (value.length > 7 && this.partneruser.userno <= 0) {
								let resp = await runner.doAPIGet("checkusername",{},this.$store.state.partnerid+"/user/exists/"+value);
								return !resp.data.userexists;
							} else {
								return true; //if it's too short, we don't consider this validation. Our minLength validation will catch instead
							}
						}
					},
					firstname: {
						required
					},
					lastname: {
						required
					},
					phone: {
						required,
						numeric
					},
					email: {
						email
					},
					password: passwordValidations
				},
				passwordConfirm: {
					sameAsPassword: (password) => {return password === (this.partneruser.password || "")}
				}
			} //end validations
		}
	};
</script>
<style>
	ul li > a {
		color: var(--bs-primary);
	}
</style>


