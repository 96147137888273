<template>
  <div v-if="env!=='development'">
      {{ $t('errormessage') }}
  </div>
	<div v-else class="card errorblock">
    <div class="card-header">Errors : Debug View</div>
    <div class="card-body">
    {{ errors }}
    </div>
	</div>
</template>

<script>

export default {
  data() {
          return {
            errors: this.$store.state.arrerror,
            env: process.env.NODE_ENV
          };
    }
}
</script>