<template>
	<div class="card">
		<h3 class="card-header d-flex">
			<p class="flex-grow-1">{{areaID == 1 ? $t('sea') : $t('river')}}</p>
			<div>
				<button class="btn btn-info" v-on:click="saveTopSort()">
					<i class="fa fa-save"></i> {{$t("save")}}
				</button>
			</div>
		</h3>
		<div class="card-body">
			<SaveSpinner :loadFlag="pending" />
			<form>
				<div class="row">
					<div class="col-5">
						<label class="radio mr-4 py-2">
							<input type="radio" name="profile" value="ehoi" class="check-box" v-model="selectedProfile" />
							<span class="pl-1">{{$t("topsort_standard")}}</span>
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-5">
						<label class="radio mr-4 py-2">
							<input type="radio" name="profile" value="customized" class="check-box" v-model="selectedProfile" />
							<span class="pl-1">{{$t("customized")}}</span>
						</label>
					</div>
					<div class="col-7" v-if="selectedProfile == 'customized'">
						<div v-if="!optionsLoaded" class="text-right">
							<button type="button" class="btn btn-info" v-on:click="getTopSortItemOptions()">{{$t('edit')}}</button>
						</div>
						<div v-if="selectedOptions.length == 0 && optionsLoaded">
							<p class="alert alert-warning">{{$t("topsort_emptyselection")}}</p>
						</div>
					</div>
				</div>
				<div v-if="optionsLoaded && selectedProfile == 'customized'">
					<button type="button" class="btn">
						<i class="fa" v-bind:class="{'fa-chevron-down': showOptions,'fa-chevron-up': !showOptions}" v-on:click="() => {showOptions = !showOptions}"></i>
					</button>
				</div>
				<div v-bind:class="{'d-none': !showOptions || selectedProfile == 'ehoi'}">
					<div v-for="option in renderOptions()" :key="option.id">
						<label>
							<input type="checkbox" :value="option.id" v-model="selectedOptions" />
							<span class="pl-1">{{option.text}}</span>
						</label>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

import Vuex from "vuex";
import SaveSpinner from "../Spinner.vue";
import cloneDeep from "lodash.clonedeep";

const ms = Vuex.mapState;

export default {
	data() {
		return {
			selectedProfile: "ehoi",
			availableOptions: [],
			selectedOptions: [],
			pending: false,
			optionsLoaded: false,
			showOptions: false
		};
	},
	props:{
		areaID:{
			type: Number,
			required: true
		},
		q:{
			type: String,
			required: true
		},
		topSort:{
			type: Object,
			required: true
		}
	},
	computed: {
		...ms(["ui"])
	},
	components: { SaveSpinner },
	methods: {
		saveTopSort: async function() {
			this.pending = true;
			if(this.selectedProfile == "ehoi"){
				this.topSort.list = "";
				this.selectedOptions = [];
			}else{
				this.topSort.list = this.selectedOptions.join(",")
			}
			// this.$v.$touch();
			// this.$store.commit("resetui");
			// let topSort = cloneDeep(this.topSort);
			// this.$store.commit("setui", { loadingflag: true });
			await this.$store.dispatch("savetopsort", { topsort: this.topSort });
			// this.$store.commit("setui", { loadingflag: false });
			if(this.topSort.list.length == 0){
				this.selectedProfile = "ehoi";
			}
			this.optionsLoaded = false;
			this.showOptions = false;
			this.pending = false;
		},
		filterOptions: function(items){
			this.availableOptions = items.filter((option) => {
				return option.area == this.areaID || option.area == 3;
			});
		},
		getTopSortItemOptions: async function() {
			this.pending = true;
			try {
				let topSortOptions = [];
				if(this.q == "destination"){
					if(this.$store.state.partnertopsortoptions.destination.length == 0){
						await this.$store.dispatch("gettopsortdestination", {});
					}
					topSortOptions = cloneDeep(this.$store.state.partnertopsortoptions.destination);
				}else if(this.q == "organizer"){
					if(this.$store.state.partnertopsortoptions.organizer.length == 0){
						await this.$store.dispatch("gettopsortorganizer", {});
					}
					topSortOptions = cloneDeep(this.$store.state.partnertopsortoptions.organizer);
				}else if(this.q == "ship"){
					if(this.$store.state.partnertopsortoptions.ship.length == 0){
						await this.$store.dispatch("gettopsortship", {});
					}
					topSortOptions = cloneDeep(this.$store.state.partnertopsortoptions.ship);
				}
				this.filterOptions(topSortOptions);
				this.optionsLoaded = true;
				this.showOptions = true;
			} catch {
				// this.$store.commit("setui", { errorflag: true });
				this.optionsLoaded = false;
			} finally{
				this.pending = false;
			}
		},
		renderOptions: function(){
			if(!this.showOptions){
				return [];
			}

			let tmp = this.availableOptions.map(o => { return {
				id: o.id, 
				text: o.texts.find(t => t.language == this.$i18n.locale)?.name || o.texts.find(t => t.language == "de")?.name || ""
				}
			})

			tmp.sort((a,b) => {
				if(a.text.toLowerCase() > b.text.toLowerCase()){
					return 1;
				}if(a.text.toLowerCase() < b.text.toLowerCase()){
					return -1;
				}
				return 0
			});

			return tmp;
		},
		// renderLabel: function(texts){
		// 	return texts.find(t => t.language == this.$i18n.locale)?.name || texts.find(t => t.language == "de")?.name || ""
		// }
	},
	created(){
		if(this.topSort.list.length > 0){
			this.selectedOptions = this.topSort.list.split(',');
			this.selectedProfile = "customized";
		}
	}
}
</script>