import runner from './runners/axiosrunner'




export default {
    
    //I am executed everytime this component is intialized.
    errorHandlerWithStack(err,store,stack,body,action,userAgent,path){
        let data = {}
        for (let [key, prop] of body) {
            data[key] = prop;
        }
        data = JSON.stringify(data,null,2);

        store.state.arrerror.push(err + " STACK: " + stack )
        if(process.env.NODE_ENV==='development'){
            //eslint-disable-next-line no-console
            console.log('Error: ' + err)
            let stringifiedStack = stack.map(function(sf) {
                return sf.toString();
            }).join('\n');
            //eslint-disable-next-line no-console
            console.log('Details: ' + stringifiedStack + ' on action ' + action + ' with body:')
            //console.dir(body)
            //eslint-disable-next-line no-console
            console.log(JSON.stringify(body))
            //eslint-disable-next-line no-console
            console.log('Form: ' + data)
            //eslint-disable-next-line no-console
            console.log('Page: ' + path)
            //eslint-disable-next-line no-console
            console.log('Agent: ' + userAgent)
        } else {
            let f = new FormData();
            f.set('action' , 'errorflash')
            f.set('partnerid' , store.state.partnerid) //possibly a 0, the default value
            f.set('msg', JSON.stringify(store.state.arrerror))
            runner.doPost(f) //because we've pushed the rror to our store, our UI should react and show an error message.
            .then(resp => {
                //eslint-disable-next-line no-console
                console.log(resp)
            })
            .catch(err => {
                //eslint-disable-next-line no-console
                console.log(err)
            })
        }
    }

    ,

    failFormat(err,action,body){
        return {
            errText : (typeof err !== 'undefined') ?  err : '',
            stack : '',
            action: (typeof action !== 'undefined') ?  action : '',
            body: (typeof body !== 'undefined') ?  body : '',
          }    
    } 



    }
    