<script>
  export default {    //contains stuff global to all our pages!
    methods: {
        showState: function(){
              // eslint-disable-next-line no-console
              // console.log(this);
        },
        showParent: function(){
              // eslint-disable-next-line no-console
              // console.log(this.$parent);
        },
        setBool(v,p){ 
                  if(v===true){
                    eval("this."+p+"=1")
                  } else {
                    eval("this."+p+"=0")
                  }
        },
        getBool(p){
                  let v = parseInt(eval("this."+p))
                  if(v > 0 ){
                      return true
                  } else {
                    return false
                  }
        }
    },
    computed: {
        partnerid_store() {return this.$store.state.partnerid}, 
        error() {return this.$store.state.ui.errorflag},
        loadingflag() {return this.$store.state.ui.loadingflag}
    },

    //I am executed everytime this component is intialized.
    created(){
         this.$store.commit('resetui' ) //sets loading flag, error feedback etc back to defaults.
    }
  }
</script>