<template>
	<div class="input-group">
		<input
			:type="inputType"
			:value="value"
			class="form-control"
			:autocomplete="autocomplete"
			@input="$emit('input', $event.target.value)"
		/>
		<div class="input-group-append">
			<div class="input-group-text">
				<i class="fa fa-eye" @mouseover="() => {inputType = 'text'}" @mouseleave="() => {inputType = 'password'}"></i>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				inputType: "password"
			}
		},
		props: {
			value: {
				type: String,
				default: () => ""
			},
			autocomplete: {
				type: String,
				default: () => ""
			}
		},
		name: 'VPassword'
	}
</script>