<template>
        <div>
          <div class="alert alert-danger" v-if="ui.errorflag">{{ $t(ui.feedback) }}</div>
          <transition name="fade">
            <div class="alert alert-success" v-if="ui.successflag">{{successMessage}}</div>
          </transition>
        </div>
</template>

<script>
import Vuex from 'vuex'
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;

export default {
        props:['successMessage'],
        computed: { 
        ...ms([ 'ui' ])
    }
}
</script>


<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}


</style>