<template>
	<select class="form-control" v-model="theselect" :required="required">
		<option v-if="d" value=""></option>
		<option
			v-for="option in lowerCaseValue"
			v-bind:key="option.value"
			v-bind:value="option.value"
		>
			{{ option.text }}
		</option>
	</select>
</template>

<script>
	//Note: if your data in theselect has a different name than that in q, it won't work.
	export default {
		name: 'VSelect',
		props: {
			required: {
				type: Boolean,
				default: () => false
			},
			v: {
				type: Object,
				required: true
			},
			q: {
				type: Array,
				required: true
			},
			d: {
				type: Boolean,
				default: false
			},
			value: {
				required: true
			}
		}
		,
		computed: {
			theselect: {
				get() { //the component renders synchronously, before our ajax is complete. the value will initially be undefined. Make sure we handle this.
					if(typeof this.value === 'undefined') {
						return ''
					}
					else if(typeof this.value === 'string') {
						return this.value.toLowerCase()
					}
					else {
						return this.value
					}
				},
				set(v) {

					this.$emit('input', v);
				},
			},
			lowerCaseValue: function() {
				if(typeof this.q === 'undefined') {
					return ''
				} else {
					this.q.forEach(option => {
						if(option.value != null && (typeof option.value === 'string' || option.value instanceof String)) {
							option.value = option.value.toLowerCase()
						}
					});
					return this.q
				}

			} //end lowercasevalue
		}
	}
</script>