<template>
	<Layout v-bind:subhead="false">
		<div id="formContent" class="mx-auto">			
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div class="alert alert-danger" v-if="invalid">{{$t('invalidlogin')}}</div>
		</div>
	</Layout>
</template>
 

<script>
import Layout from "../layouts/Layout.vue";
import SaveSpinner from "../views/Spinner.vue";
//abstract page
import Page from "../pages/Page.vue";
import Vuex from "vuex";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;
export default {
	data() {
		return {
			invalid: false
		};
	},
	components: { Layout, SaveSpinner },
	computed: {
			...ms(["ui"])
	},
	mixins: [Page],
	methods: {
		login: async function() {
			let querys = new URLSearchParams(window.location.search);
			this.$store.commit("setui", { loadingflag: true })
			try {
				await this.$store.dispatch("loginehoi", parseInt(querys.get("partnerID")));
				this.$store.commit("setui", { loadingflag: false })
				this.$router.push("/home");
			} catch(err) { //failed logins should not be reported as errors. Thus we try/catch them
				this.$store.commit("setui", { loadingflag: false })
				this.invalid = true //failed login attempt.
				//if the status code is 500, we report a server error. Otherwise this is a failed login
				if(err.errText.toString().indexOf('status code 500') > -1)
				{
					throw(err)
				}

			}
		}
	},
	mounted(){
		this.login();
	}
};
</script>
<style>
@media (max-width: 991px) {
	input {
		margin: 8px 8px;
	}
	.wrapper {
		padding-top: 8rem;
	}
}
.wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100%;
	padding: 80px;
}

#formContent {
	border-radius: 10px 10px 10px 10px;
	background: #fff;
	width: 100%;
	max-width: 650px;
	box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
	text-align: center;
}

#formFooter {
	background-color: #f6f6f6;
	border-top: 1px solid #dce8f1;
	padding: 12px;
	text-align: center;
	border-radius: 0 0 10px 10px;
}

button {
	background-color: var(--bs-primary);
}

.underlineHover {
	color: var(--bs-primary);
}
#icon {
	width: 20%;
	margin-top: 10px;
}
</style>
