import axios from 'axios'

const axiosrunner = axios.create({})

// before a request is made start the nprogress
//axiosrunner.interceptors.request.use(config => {
//we are now exporting these and using in our store where the methods are.
//  return config
//})

//see .env files and vue.config.js for proper path building to extern dependencies.
/* in localdev, we must change the line below to read 'local' if we want to directly access the
java api on localhost. This is because our wrapper is Coldfusion and must run on dev2, but Coldfusion cannot
access the IPs of our local machines.
However, when committing, the line MUST READ deploy, since local will not work in a deploy environment!
*/
//var env = 'deploy'
var env = 'local'
var bridgepath = '/bridge/bridge.cfm'
var localApiPath = '/api'
var apipath = '/api/wrapper.cfm'
var runners = {
	"authentication": {
		"deploy": apipath+"?pfd=/authenticate",
		"local": localApiPath + "/authenticate"
	},
	"checkpartner": {
		"deploy": apipath+"?pfd=/checkpartner",
		"local": localApiPath + "/checkpartner"
	},
	"loginehoi": {
		"deploy": apipath+"?pfd=/loginehoi",
		"local": localApiPath + "/loginehoi"
	},
	"getstammdaten": {
		"deploy": apipath+"?pfd=/reference",
		"local": localApiPath + "/reference"
	},
	"getpartner": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"getpartnertopsort": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"gettelnumbers": {
		"deploy": apipath+"?pfd=/reference",
		"local": localApiPath + "/reference"
	},
	"listProvisions": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"getorganizer": {
		"deploy": apipath+"?pfd=/organizer",
		"local": localApiPath + "/organizer"
	},
	"getdestination": {
		"deploy": apipath+"?pfd=/destination",
		"local": localApiPath + "/destination"
	},
	"getship": {
		"deploy": apipath+"?pfd=/ship",
		"local": localApiPath + "/ship"
	},
	"getpartnerhits": {
		"deploy": apipath+"?pfd=/partner/statistics",
		"local": localApiPath + "/partner/statistics"
	},
	"listtransactions": {
		"deploy": apipath+"?pfd=/partner/statistics",
		"local": localApiPath + "/partner/statistics"
	},
	"getpartnerstatistics": {
		"deploy": apipath+"?pfd=/partner/statistics",
		"local": localApiPath + "/partner/statistics"
	},
	"listyearlystats": {
		"deploy": apipath+"?pfd=/partner/statistics/yearly",
		"local": localApiPath + "/partner/statistics/yearly"
	},
	"listPartnerOrganizerData": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"savePartnerOrganizerData": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"savepartner": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"savepartneruser": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"deletepartneruser": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"getwlv": {
		"deploy": apipath+"?pfd=/wlv",
		"local": localApiPath + "/wlv"
	},
	"savewlv": {
		"deploy": apipath+"?pfd=/wlv",
		"local": localApiPath + "/wlv"
	},
	"checkusername": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"listpartnerusers": {
		"deploy": apipath+"?pfd=/partner",
		"local": localApiPath + "/partner"
	},
	"passwordreset": {
		"deploy": apipath+"?pfd=/passwordreset",
		"local": localApiPath + "/passwordreset"
	},
	"passwordchange": {
		"deploy": apipath+"?pfd=/passwordchange",
		"local": localApiPath + "/passwordchange"
	},
	"errorflash": {
		"deploy": bridgepath,
		"local": bridgepath
	}
}




export default {
	axiosinterceptor: axiosrunner.interceptors,
	doBlob: (action,p,path,headers) => fetch(runners[action][env]+"/"+path,{method: 'POST', body: JSON.stringify(p), headers: headers}),
	doBlobGet: (action,p,path,headers) => fetch(runners[action][env]+"/"+path,{method: 'GET', params:p, headers: headers}),
	doAPIPost: (action,p,path) => axiosrunner({url: runners[action][env]+"/"+path, data: p, method: 'POST', headers: { 'poster':'post', 'Content-Type':'application/json;charset=utf-8' }}),
	doAPIGet: (action,p,path) => axiosrunner({url: runners[action][env]+"/"+path, params: p, method: 'GET', headers: { 'getter':'get', 'Content-Type':'application/json;charset=utf-8' }}),
	doAPIDelete: (action,p,path) => axiosrunner({url: runners[action][env]+"/"+path, params: p, method: 'DELETE', headers: {  'Content-Type':'application/json' }}),
	doPost: (p) => axiosrunner({url: runners[p.get('action')][env], data: p, method: 'POST'}),
	doGet: (p) => axiosrunner({url: runners[p.action][env], params: p, method: 'GET'})
}