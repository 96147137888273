<template>
	<label class="checkbox border-ehoi" v-bind:class="{checked: theboxvalue}">
		<span class="pr-1">{{label}}</span>
		<i class="fa fa-check"></i>
		<input type="checkbox" class="form-control check-box d-none" v-model="theboxvalue" />
	</label>
</template>
<!-- type: [String, Number] v-model retunrs String and :value === v-model innerhalb einem Array(v-bind:value) returns a Number -->
<script>
	export default {
		name:'VCheckbox',
		props:{
			value:{
				type: [Boolean], 
				required:false,
				default:false
			},
			label: {
				type: [String],
				required: false,
				default: ""
			}
		},
		computed:{
			theboxvalue:{
				get(){
					if(typeof this.value === 'undefined'){
						return false
					} else {
						return this.value
					}
				},
				set(v){
					this.$emit('input',v);
				}
			}
		},
		methods: {
		}
	}
</script>

<style>
	label.checkbox{
		border-radius: 6px;
		padding: .25rem .5rem;
		background-color: #fff;
		color: var(--bs-primary);
	}

	label.checkbox i{
		color: #fff;
	}

	label.checkbox.checked{
		background-color: var(--bs-primary);
		color: #fff;
	}

	input[type="checkbox"]{
		height: 1em;
	}
</style>